import { Card, Col, Form, Row } from "react-bootstrap";
import { Input, Label } from "reactstrap";

function CreateProject(){
    return <div>
           <Card className="mt-5">
           <Card.Body>
        <Card.Title>Create Peoject</Card.Title>
              <Row className="mt-5">
                <Col>
                <Label> Project Name  :</Label>
                 <Input type="email"  placeholder="Enter project Name"></Input>
                </Col>
                
              </Row>
              <Row className="mt-5">
                <Col>
                <Label> Description :</Label>
                 <Input type="password"  placeholder="Enter project description"></Input>
                </Col>
                
              </Row>
            
          <Row className="mt-5">
          <Label> Project type :</Label>
          <div key={`inline-radio`} >
     
     <Form.Check
       inline
       label="Type 1"
       name="group1"
       type={"radio"}
      
     />
     <Form.Check
       inline
       label="Type 2"
       name="group1"
       type="radio"
     
     /> 
   </div>
 
          </Row>
       
 
              </Card.Body>
           </Card>
    </div>
}
export default CreateProject;