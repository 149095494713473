
import Divider from '@mui/material/Divider';
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import Link from "@mui/material/Link";

import '../../App.css'
import TextField from '@mui/material/TextField';
import { Console } from 'console';
import { Button, Container, FormControl, FormControlLabel, FormLabel, listClasses, Radio, RadioGroup } from '@mui/material';
import { Rule, RuleCondition } from '../../models/Rule';
import authService from '../../services/Auth_service';
import Ruleservice from '../../services/RuleGestion';
import CustomizedSnackbars from './Sncackbar';
import Conditions from './Condition';
import { useNavigate } from 'react-router-dom';
import { JsxAttribute } from 'typescript';
import {v4 as uuidv4} from 'uuid';
function Addrule(){
  const callback=(consditions:RuleCondition)=>{
    rule_conditions.push(consditions);
     console.log(rule_conditions);       
};
const deleteRule=(i:any)=>{
  setConditionList(conditionList.filter((obj) => {return obj.props.index!== i}));
 
}
  const [open,setOpen]=useState(false);
  const [rulename,setRulename]=useState('');
  const [ruledesc,setRuledesc]=useState('');
  const [rule_action_type,setRule_action_type]=useState('');
  const [rule_action_reason,setRule_action_reason]=useState('non');
  const [rule_conditions,setRule_conditions]=useState<Array<RuleCondition>>([]);
  const [conditionum,setConditionum] = useState(0);

  const [conditionList,setConditionList]=useState<JSX.Element[]>([]);
  const navigate = useNavigate();
  
  const [user_id,setUser_id]=useState("")
 
    useEffect(()=>{
     if(authService.getCurrentuserRole() as unknown as string =='spectateur'){
      navigate("/Listrules");
     }
    },[]);
    

    const reasons = ['animals','scum','illegal','images','arms','abuse','offensive'];
   const handelcolse =()=>{
    setOpen(false);
    navigate("/Listrules");
   }
  
    return <>
   
      <Container style={{marginTop:30}}>
    <Row>
        <Col >
        <Card body style={{alignSelf:'center'}}> 
        <div style={{'marginTop':20}}>
        <Row>
        <Col>  <label htmlFor="formGroupExampleInput" className="form-label">Rule Name</label>
  <input type="text" value={rulename} onChange={(text)=>{setRulename(text.target.value)}} className="form-control" id="formGroupExampleInput" placeholder="Rule Name"/></Col>
        <Col>  <label htmlFor="formGroupExampleInput" className="form-label">Rule Description </label>
  <input type="text" className="form-control"  value={ruledesc} onChange={(text)=>{setRuledesc(text.target.value)}}  id="formGroupExampleInput" placeholder="Rule Description"/></Col>
      </Row>
        </div>

       <div style={{'marginTop':20}}>
        <Row>
            <Col> If an item matches all of the following conditions ? </Col>
            
        </Row>
        {conditionList.map((condition)=>{
          return condition;
        })}
      
       
            <div style={{justifyContent:"center",display:"flex", marginTop:30}}>
             <button  className="btn primary bg-primary" onClick={() => {setConditionum(conditionum+1);
            console.log(conditionum);
           conditionList.push(<Conditions key={conditionum} callback={callback} deleterule={deleteRule} index={conditionum}  ></Conditions>);
           setConditionList(conditionList)
            
             }}   type="button"><span style={{'color':'white'}}>Add Condition</span></button> 
            </div>
       
        <div style={{'marginTop':20}}>
        <Row>
            <Col> Then do one of the following actions:  </Col>
            
        </Row>
        </div>
        <Row>   <Col> <hr style={{ 'color': 'black' }} />  </Col>  </Row>        
        <Row>
            <Col>
            <Card body>
             <Row>
                <Col style={{justifyContent:"center",display:"flex", marginTop:30}}>

    <FormControl>
     
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={(value)=>{setRule_action_type(value.target.value)}}
      >
        <FormControlLabel value="No action" control={<Radio />} label="No action" />
        <FormControlLabel value="Manual" control={<Radio />} label="Manual" />
        <FormControlLabel value="Refuse" control={<Radio />} label="Refuse" />
      </RadioGroup>
    </FormControl>


                </Col>
             
             </Row>
             {rule_action_type=="Refuse"?  <div style={{marginTop:10}}>
                <Row>
                    <Col style={{justifyContent:"center",display:"flex", marginTop:30}}>  <Autocomplete
                     onChange={(event, newValue)=>{setRule_action_reason(newValue || '')}}
                                    disablePortal
                                    id="combo-box-d"
                                    options={reasons}
                                    sx={{ width: 400 }}
                                    renderInput={(params) => <TextField {...params}  onChange={(value)=>{setRule_action_reason(value.target.value)}}  label="Select reason" />} /> </Col>
                </Row>
             </div>:
             <div>
              
             </div>
             }
           
             <div className='row mt-10' style={{marginTop:20}}>
               
                 <div className='col' style={{justifyContent:"center",display:"flex"}}>
                 <button className="btn primary bg-primary" onClick={()=>{
          const r : Rule = {rule_name:rulename,rule_action_reason:rule_action_reason,active:true,rule_description:ruledesc,user_id:authService.getCurrentUser(),rule_action_type:rule_action_type,rule_conditions:rule_conditions} as Rule
          Ruleservice.AddRule(r).then(()=>{setOpen(true)});
        }} style={{width:200}} type="button"><span style={{'color':'white'}}>Add Rule</span></button>
                 
                 </div>
                 
             </div>
            </Card>
            </Col>
        </Row>
       
        </div> 

      
     
    </Card></Col> 
    </Row>
   
    <CustomizedSnackbars color='green' open={open} message='Rule added successfully'  handelclose={handelcolse}></CustomizedSnackbars>
</Container>

    

 
    </>
}
export default Addrule

function getCurrentuserRole() {
  throw new Error('Function not implemented.');
}
  