import { title } from "process";
import { Col } from "react-bootstrap";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';

export default function DashCard(props:{imageblue:any,title:string,rate:any,derction:string,color:string,index:number}){
    return   <div   style={{ position: 'relative',boxShadow: '1px 2px 9px #F4AAB9 #000000', backgroundColor:"white",borderRadius: '5px',marginBottom: "0px",padding:"2px",marginTop:'10px'}}>
   
           
            <div className="row mt-3 ml-1 ">
              <div className="col ">
             <h5 style={{color:"#445C67"}}> {props.title}</h5>
              </div>
            </div>
               

            <div className="row" style={{marginTop:10}}>
            <div className="col-2 ">
                {props.derction=="up"?<TrendingUpIcon style={{color:props.color,width:"30px" ,height:"30px"}}></TrendingUpIcon>:<TrendingDownIcon  style={{color:props.color,width:"30px" ,height:"30px"}}></TrendingDownIcon>
                }
            
                
                </div>
                <div className="col" style={{verticalAlign:"bottom" ,position: 'absolute',left:"20px"}} >
                {props.rate==null?<h5 style={{color:"#455964"}}>{0}</h5> :<h5 style={{color:"#455964"}}>{props.rate}</h5>}
                
                </div> 
                <div className="col" style={{justifyContent:"flex-end" ,verticalAlign:"top",display:"flex"}}>
               <img className="img-fluid" style={{width:"80px",height:"60PX",marginBottom:"5px", float:'left'}} src={props.imageblue} alt="tan" />
                </div>
            
            </div>
          
       
    </div>
}