import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { color } from '@amcharts/amcharts5';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars(props:{open:boolean,message:string,handelclose:any,color:any}) {
 
  
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
     
      <Snackbar open={props.open} autoHideDuration={3000}  onClose={props.handelclose}>
        {props.color=="red"?<Alert onClose={props.handelclose} severity="error" sx={{ width: '100%' }}>
         {props.message}
        </Alert>:<Alert onClose={props.handelclose} severity="success" sx={{ width: '100%' }}>
         {props.message}
        </Alert>

        }
        
      </Snackbar>
    </Stack>
  );
}
