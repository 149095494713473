
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import {Col,Row} from 'react-bootstrap'
import Container from "react-bootstrap/esm/Container"
import TextField from "@mui/material/TextField/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Divider from "@mui/material/Divider";
import { Sparklines,SparklinesLine  } from 'react-sparklines';
import {Barchartd} from "../Charts/barcharts";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState } from "react";
import Doublelinechart from "../Charts/doublelinechart";
import { margin } from "@mui/system";
import imageblue from './barcharimge.png';
import imagepink from './pinkimage.png';
import purpuleimage from './purpuleimage.png'
import Dashcard from "./Dashcard";
import skyimage from './skyimage.png';
import Drop from "./dropdown";
import Dashbordservice from '../../services/dashbordService';
import authService from '../../services/Auth_service';

import {

  useMediaQuery,
  useTheme,
} from '@material-ui/core';

const years= [2019,2020,2021,2022,2023];
const mois= ["January", "February", "March", "April", "May", "June", "July","August","September","October","November","December"]

function Dashbord(){
  const theme = useTheme();
  const isMid = useMediaQuery(theme.breakpoints.down('md'));
const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [datestart, setDatestart] = useState(new Date());
    const [dateend, setDateend] = useState(new Date());
    const [year1 ,setYear1]=useState(2022);
    const [year2 ,setYear2]=useState(2023);
    const [yearm1 ,setYearm1]=useState(2022);
    const [yearm2 ,setYearm2]=useState(2023);
    const [month1 ,setMonth1]=useState("June");
    const [month2 ,setMonth2]=useState("July");
    const [totalrefuse,setTotalrefuse]=useState(null);
    const [overall,setOverall]=useState(0);
    const [totalmanual,setTotalmanual]=useState(null);
    const [totalnoaction,setTotalnoaction]=useState(null);
    const [totaldecision,setTotaldecision]=useState(null);
    const [totaldup,setTotaldup]=useState(null);
    const [overview,setOverview]=useState([]);
    const [yearvsyear,setYearvsyear]=useState();
    const [monthvsmonth,setMonthvsmonth]=useState([]);
    const [cards,setCards]=useState([]);
    const [loading,setLoading]=useState(true);
    const handleChangestart = (newValue) => {
        setDatestart(newValue);
      };
      const handleChangeend = (newValue) => {
        setDateend(newValue);
      };
      const handleyearvsyear = (y1,y2) => {
        Dashbordservice.Getyearvsyear(authService.getCurrentUser(),y1,y2).then((data)=>{
          setYearvsyear(data);
      });
      };
      const handlemonthvsmonth = (y1,m1,y2,m2) => {
        Dashbordservice.GetMonthvsMonth(authService.getCurrentUser(),y1,m1,y2,m2).then((data)=>{
          setMonthvsmonth(data);
      });
     
      };
      useEffect(() => {
     
            const delayDebounceFn = setTimeout(() => {
              setLoading(false)
              }, 3000)
            
              return () => clearTimeout(delayDebounceFn)
      
        
      }, [])


      useEffect(()=>{
    /* Dashbordservice.Getdates(authService.getCurrentUser()).then((data)=>{
        setDateend(data.date_end);
        setDatestart(data.date_start);
      });*/
      Dashbordservice.Getcards(authService.getCurrentUser(),datestart.toString(),dateend.toString()).then((data)=>{
       
         setTotalmanual(data.data.total_manual);
         setTotaldecision(data.data.total_decision);
         setTotalrefuse(data.data.total_refused);
         setTotalnoaction(data.data.total_no_action);
         setTotaldup(data.data.total_dup)
      });
      Dashbordservice.GetMonthvsMonth(authService.getCurrentUser(),yearm1,month1,yearm2,month2).then((data)=>{
        setMonthvsmonth(data);
    });
      Dashbordservice.Getyearvsyear(authService.getCurrentUser(),year1,year2).then((data)=>{
        setYearvsyear(data);
       
      });
    
     
      },[]);

       
    return loading?<div>loading ...</div> : <div >
      
      
         <Row>
            <Col> <Divider style={{margin:10}} color="black"></Divider> </Col>
         </Row>
          <Row  >
          {isSmallScreen?(<><Col sm="4">
          <Dashcard derction="up" key={1} index={1} color="#0CA8F2" imageblue={imageblue} rate={totaldecision} title="Total Decisions"></Dashcard>
        </Col><Col sm="4">
            <Dashcard derction="up" key={2} index={2} color="#00C5D8" imageblue={skyimage} rate={totalmanual} title="Total Manual"></Dashcard>
          </Col><Col sm="4">
            <Dashcard derction="up" key={3} index={3} color="#7966EF" imageblue={purpuleimage} rate={totalnoaction} title="Total No Action"></Dashcard>
          </Col><Col sm="4">
            <Dashcard derction="down" key={4} index={4} color="#F83A5B" imageblue={imagepink} rate={totalrefuse} title="Total Refused"></Dashcard>
          </Col><Col sm="4">
            <Dashcard derction="up" key={4} index={4} color="#00C5D8" imageblue={skyimage} rate={totaldup} title="Total Duplicates"></Dashcard>
          </Col></>):( <><Col >
            <Dashcard derction="up" key={1} index={1} color="#0CA8F2" imageblue={imageblue} rate={totaldecision} title="Total Decisions"></Dashcard>
          </Col><Col >
              <Dashcard derction="up" key={2} index={2} color="#00C5D8" imageblue={skyimage} rate={totalmanual} title="Total Manual"></Dashcard>
            </Col><Col >
              <Dashcard derction="up" key={3} index={3} color="#7966EF" imageblue={purpuleimage} rate={totalnoaction} title="Total No Action"></Dashcard>
            </Col><Col >
              <Dashcard derction="down" key={4} index={4} color="#F83A5B" imageblue={imagepink} rate={totalrefuse} title="Total Refused"></Dashcard>
            </Col><Col >
              <Dashcard derction="up" key={4} index={4} color="#00C5D8" imageblue={skyimage} rate={totaldup} title="Total Duplicates"></Dashcard>
            </Col></>)

          }
                 
                     
                     
                   
            
          </Row>
          <Row  style={{marginTop:10,marginBottom:30}}>
        
          <Col className=" p-4 ">
          <div className='bg-white p-3'>
            
          <Doublelinechart data={yearvsyear}  type={1} title="Total"></Doublelinechart>
          </div>
        
          </Col >
          
          </Row>
          <Row style={{marginBottom:30}}>
            <Col  >
            <div className="bg-white m-2 mt-0 ">
            <div>
              
              </div>
              <div className="row " >
              <div className="col m-3">
              <DropdownButton
            style={{backgroundColor:"#35DDD6"}}
              as={ButtonGroup}
              variant="primary"
              title={year1}
              
            >
              {years.map((l,index)=>(
                 <Dropdown.Item onChange={()=>{
                  setYear1(l)
                  handleyearvsyear(l,year2);
                }} onClick={()=>{
                  setYear1(l)
                  handleyearvsyear(l,year2);
                }} eventKey={index}>{l}</Dropdown.Item>
              ))}
              
             
            </DropdownButton>
              </div>
              <div className="col d-flex justify-content-end m-3">
              <DropdownButton
            style={{backgroundColor:"#35DDD6"}}
              as={ButtonGroup}
              variant="primary"
              title={year2}
              
            >
              {years.map((l,index)=>(
                 <Dropdown.Item onChange={()=>{
                  setYear2(l)
                  handleyearvsyear(year1,l);
                }} onClick={()=>{
                  setYear2(l)
                  handleyearvsyear(year1,l);
                }} eventKey={index}>{l}</Dropdown.Item>
              ))}
              
             
            </DropdownButton>
              </div>
             
            </div>
              <Doublelinechart data={yearvsyear} type={2} title="Year Vs Year"></Doublelinechart>
            </div>
            </Col>
            
           
          </Row>
      
    </div>
}

export default Dashbord
