import AppBar from "@mui/material/AppBar/AppBar";
import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import IconButton from "@mui/material/IconButton/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import { TransitionProps } from "@mui/material/transitions/transition";
import React from "react";
import { useEffect } from "react"
import {Document} from "../../models/items"
import Doc from "../items/document";
import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography/Typography";
import DescriptionIcon from '@mui/icons-material/Description';
import { height } from "@mui/system";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
function Documentlist(props: { docs: Document }){

    const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

    useEffect(()=>{
      console.log(props.docs)
    },[])
    return  <div>
        
      <Button variant="outlined" onClick={handleClickOpen}>
      {props.docs.src.charAt(props.docs.src.length-1)=='f'?
                      <Doc link={props.docs.src} allpages={false}></Doc>:
                     <img src={props.docs.src} style={{width:"200px", height:"250px"}} alt="alt"  />
                   }
      </Button>
     
      <Dialog
        style={{backgroundColor:'gray'}}
        
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
             document id : {props.docs.id}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className="center" >
        {props.docs.src.charAt(props.docs.src.length-1)=='f'?
                      <Doc  link={props.docs.src} allpages={true}></Doc>:
                     <img style={{width:"600px",height:"700px"}} src={props.docs.src}  alt="alt"  />
                   }
       
        </div>
      
      </Dialog>
    </div>    
}
export default Documentlist
