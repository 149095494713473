import React, { useState, useContext, useEffect } from 'react';
export const ApplicationContext = React.createContext({
  has_next: false,
  has_prev:false,
  setControllers: (has_next:boolean,has_prev:boolean) => {},
});

const AppContext = (props: any) => {
   const  [has_next,setHas_next]=useState(false);
   const  [has_prev,setHas_prev]=useState(false);
   const setControllers=(has_next:boolean,has_prev:boolean) => {
    setHas_next(has_next);
    setHas_prev(has_prev);
   }
  
  useEffect(() => {

  }, []);

  return (
    <ApplicationContext.Provider
      value={{
        has_next:has_next,
        has_prev:has_prev,
        setControllers: setControllers,
      }}
    >
      {props.children}
    </ApplicationContext.Provider>
  );
};

export default AppContext;