import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Addrule from '../rules/addruel';
import Invituser from './invituser';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HandymanIcon from '@mui/icons-material/Handyman';
import GavelIcon from '@mui/icons-material/Gavel';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ManualItem from '../Manual/ItemManual';
import { useNavigate } from 'react-router-dom';
import Itemservice from '../../services/ItemsService';
import authService from '../../services/Auth_service';
import Link from '@mui/material/Link';
import Navlist from '../layout/Navlist';
import UserSettings from '../layout/usersetting';
import { Col,Row } from 'react-bootstrap';
import MenuIcon from '@material-ui/icons/Menu';
import Userprofile from './profile';
import {

  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';


const drawerWidth = 200;

interface Props {
  content: React.FC;
}

export default function Profilelayout({content:Content}) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
const toggleDrawer = () => {setIsOpen(!isOpen);console.log(isOpen)};
  const navigate = useNavigate();
  
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
       
        
          
        <Row>
          <Col>
          <Toolbar>
          {isSmallScreen && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>)}
        <img style={{marginLeft:'1px'}} src="/logo@2x.png" alt="allo" width={'160px'} height={'70px'} />
         
        
        </Toolbar>  
          </Col>
          <Col  className='d-flex justify-content-end me-4 '>
        
          <UserSettings></UserSettings>
          
         
          </Col>
         
        </Row>
       
      
        
      </AppBar>
      {isSmallScreen ? (
      <Drawer
      
      open={isOpen} onClose={toggleDrawer}
    
        sx={{

          width:isSmallScreen?100: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >

        <Toolbar />
        <Box marginTop={5} sx={{ overflow: 'auto' }}>
        <Navlist></Navlist>
        </Box>
       
      </Drawer>
       ):(<Drawer
        variant="permanent"
       open={isOpen} onClose={toggleDrawer}
      
         sx={{
 
           width:isSmallScreen?100: drawerWidth,
           flexShrink: 0,
           [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
         }}
       >
 
         <Toolbar />
         <Box marginTop={5} sx={{ overflow: 'auto' }}>
         <Navlist></Navlist>
         </Box>
        
       </Drawer>)}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
          <div>
         <Content></Content>
          </div>
      </Box>
    </Box>
  );
}
