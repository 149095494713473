import { useState } from "react";
import authService from "../../services/Auth_service";


import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import CustomizedSnackbars from "../rules/Sncackbar";
import { fireEvent } from "@testing-library/react";

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://modera.ai/" target="_blank">
        Modera
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Login() {
    const [open, setOpen] = useState(false);
    const [openf, setOpenf] = useState(false);
    const [openw, setOpenw] = useState(false);
    const navigate = useNavigate();
     const handelclose =()=>{
        setOpen(false);
        setOpenf(false)
        setOpenw(false)
        navigate("/");
     }
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
   
    authService.login(data.get('email')?.toString()|| '',data.get('password')?.toString()|| '').then((data)=>{
        if(data.msg=="success"){
            setOpen(true);
           
        }else{
        setOpenw(true);
        }
    })  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
         
            <img src="/logo@2x2.png" alt="log" style={{width:'160px',height:'90px'}}/>
          
         
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container  alignItems="center" justifyContent="center">
              <Grid  >
                <Link href="#" variant="body2" onClick={()=>setOpenf(true)}>
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
        <CustomizedSnackbars  color="green"  open={open} message='You are logged in successfully'  handelclose={handelclose}></CustomizedSnackbars>
        <CustomizedSnackbars  color="green"  open={openf} message='Please contact our support team by email'  handelclose={handelclose}></CustomizedSnackbars>
        <CustomizedSnackbars  color="red"  open={openw} message='Invalid username or password'  handelclose={handelclose}></CustomizedSnackbars>
      </Container>
    </ThemeProvider>
  );
}