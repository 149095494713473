import * as React from 'react';
import List from '@mui/material/List';
import AppContext from '../../AppContext/Appcontext';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HandymanIcon from '@mui/icons-material/Handyman';
import GavelIcon from '@mui/icons-material/Gavel';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import Itemservice from '../../services/ItemsService';
import authService from '../../services/Auth_service';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { ApplicationContext } from '../../AppContext/Appcontext';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import { useContext,useState } from 'react';
import { RootState } from "../../redux/Store";
import { useDispatch, useSelector } from "react-redux";
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import {
  updatehasnext,
  updatehasprev,
  updateitemid,
  updatepage
} from "../../redux/counterSlice";
import { Page } from 'react-pdf';
import CustomizedSnackbars from '../rules/Sncackbar';
import Bookmarks from '@mui/icons-material/Bookmarks';


export default function Navlist(){
   const [open,setOpen]=useState(false);
  const dispatch = useDispatch();
    const navigate = useNavigate();
    const handelcolse =()=>{
      setOpen(false);
     
     }
  
    return  <AppContext> <List>
    <ListItem  disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            
             <a href="/" style={{textDecoration:'none'}}> <span style={{color:"gray"}}> Overview </span></a>
          </ListItemButton>
          </ListItem>
          {authService.getCurrentuserRole()!="spectateur"&&<ListItem  disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <HandymanIcon />
            </ListItemIcon>
            <Link  onClick={()=>{
                if(authService.getCurrentuserRole()=="spectateur"){
                  setOpen(true)
                }else{
                  
                
        Itemservice.GetItemId(authService.getCurrentUser(),0).then((data)=>{
          if(data.msg=="success"){
              
                navigate("/Manualitem/"+data.item_id+'/'+data.has_next+'/'+data.has_previous);
             
        
          
          }
          
        })}
      }} color='gray' underline="none" >
            Manual
             </Link>
          </ListItemButton>
          </ListItem>}
          {authService.getCurrentuserRole()!="spectateur" && authService.getCurrentuserRole()!="automation specialist"&&<ListItem  disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <GroupAddIcon />
            </ListItemIcon>
            <Link href='/users' color='gray'   underline="hover" >
             {'Users'}
             </Link>
          </ListItemButton>
          </ListItem>}
          <ListItem  disablePadding>
          <ListItemButton>
            <ListItemIcon>
            <GavelIcon />
            </ListItemIcon>
            <Link color='gray' href='/Listrules'   underline="none">
            Rules
             </Link>
          </ListItemButton>
          </ListItem>
         
          <ListItem  disablePadding>
          <ListItemButton>
            <ListItemIcon>
            <ControlPointDuplicateIcon />
            </ListItemIcon>
            <Link color='gray' href='/duplicate'   underline="none">
            Duplicates
             </Link>
          </ListItemButton>
          </ListItem>
         
          <ListItem  disablePadding>
          <ListItemButton>
            <ListItemIcon>
            <DocumentScannerIcon></DocumentScannerIcon>
            </ListItemIcon>
            <Link color='gray'   href={"/items?page=1&datestart=01/01/"+new Date().getFullYear()+"&dateend="+new Date().getDate()+"/"+(new Date().getMonth()+1)+"/"+new Date().getFullYear()+"&has_duplicates=false&filter=All&itemid=0"}   underline="none">
             Recent Items
            </Link>
          </ListItemButton>
          
      </ListItem>
     
        
        
    </List>
    <CustomizedSnackbars color='red' open={open} message='You can not  access this section as a spectateur'  handelclose={handelcolse}></CustomizedSnackbars>
    </AppContext>
}
//href={"/items?page=1&datestart="+new Date().getDate()+'/'+(new Date().getMonth()+1).toString()+'/'+new Date().getFullYear()+"&dateend="+new Date().getDate()+'/'+(new Date().getMonth()+1).toString()+'/'+new Date().getFullYear()+"&has_duplicates=false&filter=All&itemid=0"}
