
import axios from "axios";
import { Overview } from "../models/overview";


const url ="https://api.coralytics.com"
const Getdates = async (user_id:string) => {
    const response = await axios
          .post(url+"/get_dates", {
             
              
          },{
            headers: {
              "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
            }
          });
          console.log(response.data)
      return response.data;
  };

  /***************************************************************************** */
  const Getcards = async (iduser:string,datestart:string,dateend:string) => {
   
    const response = await axios
          .post(url+"/get_overview_cards", {
          

          },{
            headers: {
              "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
            }
          });
       
       
      return response.data;
  };
  /********************************************** */
  const Getoverview = async (iduser:string,action_name:string) => {
   
    const response = await axios
          .post(url+"/get_insights_overview", {
           
           
            "action_name":action_name
            
          },{
            headers: {
              "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
            }
          });
       
     
      return response.data ;
  };
  /*********************************************************************************** */
  const Getyearvsyear = async (iduser:string,year1:number,year2:number,) => {
   
    const response = await axios
          .post(url+"/get_insights_overview_years", {
          
            "date_start":{year:year1},
            "date_end":{year:year2},
            
          },{
            headers: {
              "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
            }
          });
       
       
      return response.data;
  };
  /*********************************************************************************** */
  const GetMonthvsMonth = async (iduser:string,year1:number,month1:string,year2:number,month2:string) => {
   
    const response = await axios
          .post(url+"/get_insights_overview_months", {
           
        
            "date_start":{year:year1,month:month1},
            "date_end":{year:year2,month:month2},
            
          },{
            headers: {
              "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
            }
          });
       
       
      return response.data;
  };

  /*********************************************************************************** */
const Dashbordservice={
   Getdates,Getcards,Getoverview,Getyearvsyear,GetMonthvsMonth
};
export default Dashbordservice;

