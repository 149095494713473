import { InviteUser } from "../models/InviteUser";
import axios from "axios";
import { Accepted, list_invites, Pending } from "../models/List_Invit";
const url ="https://api.coralytics.com/";
const AddInvite = async (invite:InviteUser) => {
    const response = await axios
          .post(url+"invite_user", {

            "user_id": invite.user_id,
            "invitee_email": invite.invitee_email,
            "invitee_role": invite.invitee_role
          },{
            headers: {
              "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
            }
          });
          console.log(response.data)
      return response.data;
  };

  /***************************************************************************** */
  const GetINvites = async (id:string) => {
    let accepteds : Accepted[] =[];
    let pendings : Pending[]=[]
    const response = await axios
          .post(url+"list_invites", {
             "user_id":id
          },{
            headers: {
              "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
            }
          });
       
         var i :list_invites = {accepted:response.data.accepted,msg:response.data.msg,pending:response.data.pending} as list_invites;
      return i;
  };

  /*********************************************************************************** */
const Inviteservice={
    AddInvite,GetINvites
};
export default Inviteservice;

