import Box from "@mui/material/Box";
import { useState } from "react";
import { Row,Col,Button } from "react-bootstrap";
import { Container } from "reactstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import Slider from '@mui/material/Slider';
import Stack from "@mui/material/Stack";
import Switch from '@mui/material/Switch';
import { title } from "process";
import Duplicateservice from "../../services/DuplicateService";
import authService from "../../services/Auth_service";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CustomizedSnackbars from "../rules/Sncackbar";
import { Project, Projects } from "../../models/project";
const Listaccounts=[{ProjectName:"Subcommunity",Range:"1 mounth",Listing:"2023"},
{ProjectName:"city",Range:"1 week",Listing:"1043"},
{ProjectName:"Community",Range:"1 year",Listing:"5043"},

]

function Duplicates(){
    const [status,setStatus]=useState(false);
    const [open2,setOpen2]=useState(false);
    const [message,setMessage]=useState("");
    const [duplicatelocation,setDuplicatelocation]=useState('');
    const [duplicatetime,SetDuplicatetime]=useState('');
    const locations = ["SubCommunity"];
    const times = ["1 Month"];
    const [imagescore, setImagescore] = useState<number>(90);
    const [description, setDescription] = useState<number>(90);
    const [title, setTitle] = useState<number>(90);
    const [projects, setProjects] = useState([]);
    const [checked, setChecked] = useState(false);
    const [checkeddesc, setCheckeddesc] = useState(false);
    const [checkedtitle, setCheckedtitle] = useState(false);
    const [l,setL]=useState(true);
    const [open,setOpen]=useState(false); 
    const handelcolse =()=>{
      setOpen(false);
      setOpen2(false);
     }
 const duplicate=  {
          "duplicate_location":"City",
          "duplicate_time":"1 Week",
          "image_score":"90",
           "description_score":"90",
           "title_score":"90",
           "image_checked":true,
           "description_checked":true,
           "title_checked":false

    }
  useState(()=>{
     Duplicateservice.GetDuplicates(authService.getCurrentUser()).then((data)=>{
          if(data.msg=="success"){
              setStatus(data.data.isactive);
              setTitle(data.data.title_score);
              setDescription(data.data.description_score);
              setDuplicatelocation(data.data.duplicate_location);
              SetDuplicatetime(data.data.duplicate_time);
              setCheckeddesc(data.data.description_checked);
              setImagescore(data.data.image_score);
              setChecked(data.data.image_checked);
              setCheckedtitle(data.data.title_checked);
             
              setProjects(data.projects);
             


          }
     }).then(()=>{setL(false)});
  })
  const handleChangecheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const handleChangecheckdesc = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckeddesc(event.target.checked);
  };
  const handleChangechecktitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedtitle(event.target.checked);
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    setImagescore(newValue as number);
  };
  const handleChangedesc = (event: Event, newValue: number | number[]) => {
    setDescription(newValue as number);
  };
  const handleChangetitel = (event: Event, newValue: number | number[]) => {
    setTitle(newValue as number);
  };

    return l?<div>loading...
    </div>: <Container container-fluid  style={authService.getCurrentuserRole()=="spectateur"?{pointerEvents:'none'}:{}}>
          <Box marginTop={5}>
            
            

            <Row style={{marginTop:10}}>
                <Row>
                <Col>
                <span> Duplicate Location:</span>
                </Col>
                </Row>
                <Row className="mt-2">
                <Col>
                <Autocomplete
                     onChange={(event, newValue)=>{setDuplicatelocation(newValue|| "")}}
                                    disablePortal
                                    id="combo-box-d"
                                    disabled={true}
                                    value={duplicatelocation}
                                    options={locations}
                                  
                                    renderInput={(params) => <TextField {...params}  onChange={(value)=>{setDuplicatelocation(value.target.value)}}  />} />
                </Col>
                </Row>

            </Row>
            <Row className="mt-5">
                <Row>
                <Col  style={{alignItems:'center',alignContent:'center'}}>
                <span style={{alignItems:'center',alignContent:'center'}}> Duplicate Time: </span>
                </Col>
                </Row>
               <Row className="mt-2">
               <Col>
                <Autocomplete
                     onChange={(event, newValue)=>{SetDuplicatetime(newValue || " ") }}
                                    disablePortal
                                    id="combo-box-d"
                                    value={duplicatetime}
                                    options={times}
                                     disabled={true}
                                    renderInput={(params) => <TextField {...params}  onChange={(value)=>{SetDuplicatetime(value.target.value)}}   />} />
                </Col>
               </Row>
               
            </Row>
          </Box>
          <Box marginTop={8}>
               <Row className="mt-5">
                <Col lg={2}>   <Switch
              
              checked={checked}
              onChange={handleChangecheck}
              size="medium"
              color="success"
              inputProps={{ 'aria-label': 'Active' }}
                   /> </Col >
                <Col lg={3}> <span style={{fontSize:20}}>{"Duplicate Score:"}</span> </Col>
                <Col lg={1}><ArrowForwardIosIcon ></ArrowForwardIosIcon></Col>
                <Col lg={5}> <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                 <span>0%</span>
                  <Slider  aria-label="Image Score" valueLabelDisplay="auto" value={imagescore} max={100} min={0} onChange={handleChange} />
                <span>100%</span>
                </Stack> 
                </Col>
               </Row>
              
             <Row>
             <Row style={{marginTop:"30px"}}>
                <Col></Col>
                <Col>  <Button
                disabled={authService.getCurrentuserRole()=="spectateur"}
                 onClick={()=>{
                    Duplicateservice.SetDuplicates(duplicatelocation,duplicatetime,imagescore,description,title,checked,checkeddesc,checkedtitle,authService.getCurrentUser(),status)
                    .then((data)=>{
                      if(data.msg="success"){
                       
                        setOpen2(true);
                        setMessage("Duplicate has been saved")
                      }else{
                        setMessage(data.msg);
                      }
                       
                    })
                }} className="btn btn-primary" style={{width:200}}> Save </Button> </Col>
                <Col></Col>
            </Row>
             <div className="container-fluid" style={{marginTop:"30px"}}>

<div className="row mt-6">
   <div className="col-12 m-6">
   <div style={{ justifyContent:"flex-start",display:"flex",marginBottom:10}}>
        <h3>
       Top Sub-Communities
        </h3>
       </div>
     
       <div className="card m-b-20">
           <div className="card-body">
               <table id="datatable" className="table table-striped dt-responsive nowrap table-vertical" width="100%" cellSpacing="0">
                   <thead>
                       <tr>
                       
                           <th> Community</th>
                           <th>Sub-Community</th>
                          
                           <th>Time Range</th>
                           <th>Number Of Items</th>
                           
                         
                       </tr>
                   </thead>
                   <tbody>
                       {projects.map((item:any)=>{
                           return                                          <tr>
                          
                         
                           <td>{item.Community}</td>
                           <td>{item.subCommunity}</td>
                         
                           <td>{duplicatetime}</td>
                           <td>{item.counts}</td>
                         
                         
                       </tr>

                       })}


                   
                      
                   </tbody>
               </table>
           </div>
        
       </div>
   </div>
</div>
</div>  
             </Row>
          </Box>
          <CustomizedSnackbars color='green' open={open} message='status has changed successfully'  handelclose={handelcolse}></CustomizedSnackbars>
          <CustomizedSnackbars color='green' open={open2} message={message}  handelclose={handelcolse}></CustomizedSnackbars>
    </Container>
}
export default Duplicates;