import axios from "axios";
import { Usage } from "../../models/Usage";
import authService from "../Auth_service";


const url ="https://api.coralytics.com"
const Setaction = async () => {
    const response = await axios
          .post(url+"/get_user_usages_mod", {
             "user_id":authService.getCurrentUser()
          
          },{
            headers: {
              "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
            }
          });
          console.log(response.data)
      return response.data as Usage;
  };
  export default Setaction;