
import Button from '@mui/material/Button/Button';
import { useEffect, useState,useContext } from 'react';
import Highlighter from 'react-highlight-words';

import DoneIcon from '@mui/icons-material/Done';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Divider from '@mui/material/Divider';
import { Col, Row } from 'react-bootstrap';
import ItemsService from '../../services/ItemsService';
import authService from '../../services/Auth_service';
import Actionservice from '../../services/Actionservice';
import {Document } from '../../models/items';

import TextField from '@mui/material/TextField/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate, useParams } from 'react-router-dom';
import { Applied_rules, Item } from '../../models/item';
import Link from '@mui/material/Link/Link';
import { GetAction } from '../../models/GetAction';
import { SetAction } from '../../models/SetAction';
import CustomizedSnackbars from '../rules/Sncackbar';
import ImagesCol from './images';
import Documentlist from '../Automation/Documentlist';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import Itemservice from '../../services/ItemsService';

import { RootState } from "../../redux/Store";
import { useDispatch, useSelector } from "react-redux";
import {
  updatehasnext,
  updatehasprev,
  updateitemid,
  updatepage,
} from "../../redux/counterSlice";




const rules =[{
  rule_name: "Images",
  rule_decision: "refused"
  },{rule_name: "Scum",
  rule_decision: "refused"},
{
  rule_name: "Images",
  rule_decision: "Manual"
}
]


function ManualItem()  {
  let {itemid,next,prev} = useParams();
  const has_next: boolean = useSelector(
    (state: RootState) => state.counter.has_next
  );
  const has_prev: boolean = useSelector(
    (state: RootState) => state.counter.has_prev
  );
  
  const page: number = useSelector(
    (state: RootState) => state.counter.page
  );
  const dispatch = useDispatch();

  const [items2,setItems2]=useState<Item>();
  const [action,setAction]=useState<GetAction>();
  const [loading,setLoading]=useState(true);
  const [open,setOpen]=useState(false);
  const [message,setMessage]=useState("error");
  const [refresh,setRefresh]=useState(true);
  const [show,setShow]=useState(false);
  
  const handelcolse =()=>{
    setOpen(false);
    setRefresh(!refresh);
   }
  
  const navigate = useNavigate();
  useEffect(()=>{
  console.log(page);
  if(itemid=="test"){
    Itemservice.GetItemId(authService.getCurrentUser(),0).then((data)=>{
      dispatch(updatehasnext(data.has_next));
      dispatch(updatehasprev(data.has_previous));
      dispatch(updateitemid(data.item_id));
     setMessage(data);
 })
  }else{
    ItemsService.GetItem(authService.getCurrentUser(),itemid as string).then((data)=>{
      setItems2({found_item:data.found_item,msg:data.msg});
  }).then(()=>{   Actionservice.Getaction(authService.getCurrentUser(),itemid as string).then((data)=>{
    setAction({listing_action:data.listing_action,msg:data.msg});
  });}).then(()=>{setLoading(false)});
  }
  
     }, [message]);
  const reasons = ['animals','scum','illegal','images','arms','abuse','offensive'];

         const Next=()=>{
          
            Itemservice.GetItemId(authService.getCurrentUser(),page+1).then((data)=>{
                if(data.msg=="success"){
                  dispatch(updatehasnext(data.has_next));
                  dispatch(updatehasprev(data.has_previous));
                  dispatch(updateitemid(data.item_id));
                  dispatch(updatepage(page+1))
                  console.log(has_next+" "+has_prev);
               
                    setMessage(data.msg+page);
                    navigate("/Manualitem/"+data.item_id+'/'+data.has_next+'/'+data.has_previous);
                  
                 
                }else{
                    alert(data.msg);
                 }
            
                
              })
         }
         const Prev=()=>{
          Itemservice.GetItemId(authService.getCurrentUser(),page-1).then((data)=>{
            if(data.msg=="success"){
              dispatch(updatehasnext(data.has_next));
              dispatch(updatehasprev(data.has_previous));
              dispatch(updateitemid(data.item_id));
              dispatch(updatepage(page-1))
              console.log(has_next+" "+has_prev);
            
              setMessage(data.msg+page);
              navigate("/Manualitem/"+data.item_id+'/'+data.has_next+'/'+data.has_previous);
          
            }else{
                alert(data.msg);
             }
        
            
          })
          }
    return  (loading?<div>
        loading ......
    </div> :<div bg-color='gray' className='container fuild'>

   
    <div className='row mt-20'>
        
        <div className='col-md-9 col-lg-8 mt-4 ml-4' style={{backgroundColor:'white'}}>
        <div className='d-flex justify-content-between m-3'>
            <div > <Fab  style={{backgroundColor:'white',width:150}} variant="extended" disabled={prev as string==="flase"?true:false} onClick={()=>Prev()}  >
  <ArrowCircleLeftIcon sx={{ mr: 1 }} />
  Previous
</Fab> </div> 
            <div ><Fab  style={{backgroundColor:'white',width:150}} variant="extended" disabled={next as string ==="false"?true:false} onClick={()=>Next()}  >
  
  Next
  <ArrowCircleRightIcon  sx={{ ml: 1 }} />
</Fab> </div>
          
          </div>
        <div className='row'>
            <div className="col-12 mt-5" >
            <h5> <Highlighter
         showBullets={true}
    highlightStyle={{backgroundColor:'yellow'}}
    searchWords={items2?.found_item.title_detected_words||[]}
    autoEscape={true}
    textToHighlight={items2?.found_item.content.title||''}
  /> </h5>
            </div>
           
        </div>
        <div className='row'>
            <div className="col  mt-3" >
            {items2?.found_item.content.images.length==0? <ImagesCol images={[{src:"/no_image.png"}]}></ImagesCol>:
                <ImagesCol images={items2?.found_item.content.images}></ImagesCol>
              }
            
            </div>
          <div className='row mt-4'>
            <h3>Description</h3>
            </div>  
         <div className='row mt-2'>
         <Highlighter
         showBullets={true}
    highlightStyle={{backgroundColor:'yellow'}}
    searchWords={items2?.found_item.description_detected_words||[]}
    autoEscape={true}
    textToHighlight={items2?.found_item.content.desc||''}
  />
         </div>  
         <div style={{marginTop:60}} className='row mt-8'>
            <h3>Documents</h3>
        </div> 
         <div className='row m-4'>
           {items2?.found_item.content.documents.length==0? <div className='col'>
                      <span>No Documents</span>
                 </div>:
             items2?.found_item.content.documents.map((d:Document,index:number)=>{
             
              return <div className='col' key={d.id}>
            
                  <div className='row'>
                     
                     <Documentlist docs={d} ></Documentlist>
                 
                  </div>
                 <div className='row mt-2'>
                      <span>Document {index+1}</span>
                 </div>
              </div>
             })
            }
        </div>
        </div>
       
        </div>
        <div  className='col-lg-4 col-md-3  mt-4'>
            <div style={{backgroundColor:'white',margin:10}} className='row  p-3'>
                <div className='col'>
                <div className='row' style={{justifyContent:'center',marginBottom:10}}>
                        <h3 className='d-flex justify-content-center'>{action?.listing_action.listing_action_name==""?"No Decision":action?.listing_action.listing_action_name}</h3>
                    </div>
                    <div className='row'>
                    <div className='col'>
                <Button variant="outlined" onClick={()=>{
                  var a :SetAction={user_id:authService.getCurrentUser(),listing_id:itemid||'',listing_action_name:'approve',listing_action_type:'manual',listing_action_reason:'empty'} as SetAction;
                  Actionservice.Setaction(a).then((data)=>{
                    setMessage("the Item has been approved successfully")
                        setOpen(true);
                  });
                }} color="inherit" sx={{width:130}}>
                   approve
                   <DoneIcon></DoneIcon>
                </Button>
                </div>
                <div className='col'>
                {show?   <Autocomplete
                                      onChange={(event, newValue)=>{  
                                         var a :SetAction={user_id:authService.getCurrentUser(),listing_id:itemid||'',listing_action_name:'Refuse',listing_action_type:'manual',listing_action_reason:newValue} as SetAction;
                                      Actionservice.Setaction(a).then((data)=>{
                                        setMessage("the Item has been refused successfully")
                                            setOpen(true);
                                      }); }}
           
                                    disablePortal
                                    id="combo-box-d"
                                    options={reasons}
                                 
                                    value={action?.listing_action.listing_action_reason}
                                 
                                    renderInput={(params) => <TextField {...params}   value={action?.listing_action.listing_action_reason} label="--Refuse--" />} />: 
                                    <Button variant="outlined" color="inherit"  sx={{width:140}} onClick={()=>{
                                      setShow(true);
                                    }}>
                                         Refuse
                                        <DoDisturbIcon/>
                                       </Button>}
                </div>
                <div className='row mt-5'>
               
                </div>
                    </div>
                </div>
              
            </div>
            <div style={{backgroundColor:'white',margin:10}} className='row  p-3'>
          <div className='col p-3'>
                  <div className='row'>
                <h3>User details</h3> 
                </div>
                   <div className='row'>
                <Col><Divider color="black"></Divider></Col>
                   </div>
                <Row className='mt-2'>
                <Col>User:</Col>
                 <Col><a href={"/itemsagent?page=1&datestart=01/01/"+new Date().getFullYear()+"&dateend="+new Date().getDate()+"/"+(new Date().getMonth()+1)+"/"+new Date().getFullYear()+"&has_duplicates=false&filter=All&itemid=0&agentid="+items2?.found_item.user.id}>{items2?.found_item.user.name}</a></Col>
            </Row>
            <Row className='mt-2'>
                <Col>Company Name:</Col>
                 <Col>{items2?.found_item.user.companyName}</Col>
            </Row>
            </div>
            
            </div>
            <div style={{backgroundColor:'white',margin:10}} className='row mt-3 p-3'>
                <div className='col'>
                     <Row>
                        <Col>
                        <h3>User Location</h3> 
                        </Col>
                     </Row>
                  <Row> 
                 <Col><Divider color="black"></Divider></Col>
                </Row>
           
            <Row style={{marginTop:10}}>
                <Col>User-Location:</Col>
                 <Col>{items2?.found_item.user.sessionInfo.region+","+items2?.found_item.user.sessionInfo.city+" "+items2?.found_item.user.sessionInfo.countryCode}</Col>
            </Row>
            <Row style={{marginTop:10}}>
                <Col>IP-Adresse:</Col>
                 <Col>{items2?.found_item.user.sessionInfo.ipAddress}</Col>
            </Row>
                 </div>
            </div>
            <div style={{backgroundColor:'white',margin:10}} className='row mt-3 p-3'>
                <div className='col'>
                     <Row>
                        <Col>
                        <h3>Version</h3> 
                        </Col>
                     </Row>
                  <Row> 
                 <Col><Divider color="black"></Divider></Col>
                </Row>
                <Row style={{marginTop:10}}>
                <Col>Item Id:</Col>
                 <Col>{items2?.found_item.id}</Col>
            </Row>
            <Row style={{marginTop:10}}>
                <Col>Versions Count:</Col>
                 <Col>{(items2?.found_item.versions.length||0)+1}</Col>
            </Row>
            <Row style={{marginTop:10}}>
                <Col>Current Version:</Col>
                 <Col>{items2?.found_item.version}</Col>
            </Row>
             {items2?.found_item.versions.map((vers:any,index:number)=>(
                <Row style={{marginTop:10}}>
               <Col><Link href={'/automation/'+vers._id}> {'version '+vers.version+':'} </Link> </Col>
                <Col></Col>
           </Row>
             ))

             }
                 </div>
            </div>
            <div style={{backgroundColor:'white',margin:10}} className='row mt-3 p-3'>
                <div className='col'>
                     <Row>
                        <Col>
                        <h3>Moderate Help</h3> 
                        </Col>
                     </Row>
                     <Row> 
             <Col><Divider color="black"></Divider></Col>
            </Row>
            <Row>
            <div style={{marginTop:15,marginBottom:15}}>
            <Row >
                <Col>Rule Decision:</Col>
                 <Col><a href="#">{action?.listing_action.listing_action_name==""?"No Decision":action?.listing_action.listing_action_name}</a></Col>
            </Row>
            {items2?.found_item.applied_rules.map((appllied:Applied_rules)=>(
               <Row  className='mt-2'>
                <Col> <span style={{fontWeight:"bold"}}>{appllied.rule_name}</span> <span>{"   ("+appllied.rule_decision+")"}</span> </Col>
               </Row>
             ))}
           
            </div>
            </Row>
           
                 </div>
            </div>
         
            {items2?.found_item.found_duplicates&&
            <div style={{backgroundColor:'white',margin:10}} className='row mt-3 p-3'>
                <div className='col'>
                     <Row>
                        <Col>
                        <h3>Duplicates</h3> 
                        </Col>
                     </Row>
                     <Row> 
             <Col><Divider color="black"></Divider></Col>
            </Row>
            <Row>
            <div style={{marginTop:15,marginBottom:15}}>
          
            <>
                   <Row >
                   <Col>Duplicates Count:</Col>
                    <Col>{items2?.found_item.found_duplicates.count}</Col>
               </Row>
               <Row >
                   <Col>Duplicates List:</Col>
                    <Col></Col>
               </Row>
               {items2?.found_item.found_duplicates.list.map((vers:any,index:number)=>(
                   <Row style={{marginTop:10}}>
                  <Col> <a href={"/automation/"+vers._id}> {vers.id} </a>   </Col>
                   <Col></Col>
              </Row>
                ))
   
                }
                </>
          
            </div>
            </Row>
           
                 </div>
            </div>  }
           
       
        </div>
      
    </div>
    <CustomizedSnackbars color="green" open={open} message={message}  handelclose={handelcolse}></CustomizedSnackbars>
    </div> )
 
}
export default ManualItem;