import React, { useEffect,useState } from "react";
import { Row,Col,Card} from "react-bootstrap";
import { Button, Container, FormControl, FormControlLabel, FormLabel, listClasses, Radio, RadioGroup, TextField } from '@mui/material';
import { Profile, user_object } from "../../models/Profile";
import authService from "../../services/Auth_service";
import CustomizedSnackbars from "../rules/Sncackbar";


export default function Userprofile(){
       const [user,setUser]=useState<user_object>({email:" ",firstname:" ",id:authService.getCurrentUser(),lastname:" ",role:" "});
       const [changepassword,setChangepassword]=useState(false);
       const [loading,setLoading]=useState(true);
       const [message,setMessage]=useState("");
       
       const [color,setcolor]=useState("green");
       const [open,setOpen]=useState(false); 
       const handelcolse =()=>{
         setOpen(false);
        }
       const [input, setInput] = useState({
        oldpassword: '',
        password: '',
        confirmPassword: ''
      });
     
      const [error, setError] = useState({
        oldpassword: '',
        password: '',
        confirmPassword: ''
      })
     
      const onInputChange = e => {
        const { name, value } = e.target;
        setInput(prev => ({
          ...prev,
          [name]: value
        }));
        validateInput(e);
      }
     
      const validateInput = e => {
        let { name, value } = e.target;
        setError(prev => {
          const stateObj = { ...prev, [name]: "" };
     
          switch (name) {
            case "oldpassword":
              if (!value) {
                stateObj[name] = "Please enter the old password.";
              }
              break;
     
            case "password":
              if (!value) {
                stateObj[name] = "Please enter Password.";
              } else if (input.confirmPassword && value !== input.confirmPassword) {
                stateObj["confirmPassword"] = "Password and Confirm Password does not match.";
              } else {
                stateObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword;
              }
              break;
     
            case "confirmPassword":
              if (!value) {
                stateObj[name] = "Please enter Confirm Password.";
              } else if (input.password && value !== input.password) {
                stateObj[name] = "Passwords must match.";
              }
              break;
     
            default:
              break;
          }
     
          return stateObj;
        });
      }
      useEffect(()=>{
         authService.GetProfile().then((data)=>{
          if(data.msg=="success")
          {
            setUser(data.user_object);
          }
           
            setLoading(false)
         })
      },[])
    return <div>
       <Container maxWidth='lg' style={{marginTop:20}}>
    <Row>
        <Col >
        <Card body style={{alignSelf:'center'}}> 
        <div style={{'marginTop':20}}>
        <Row className="center m-5">
            <Col className="d-flex justify-content-center">
            <h2>  User Details </h2>
            </Col>
           
        </Row>
        <Row>
            <Col> <label htmlFor="formGroupExampleInput" className="form-label">FirstName</label></Col>
        <Col> 
  <input type="text" disabled={false} className="form-control" onChange={(e)=>{setUser({...user,firstname:e.target.value})}} value={user.firstname as string}  id="formGroupExampleInput" placeholder="First Name"/></Col>
  <Col></Col> </Row>
      <Row className="mt-3"> 
        <Col><label htmlFor="formGroupExampleInput" className="form-label"  >LastName</label> </Col>
        <Col>  
  <input type="text" disabled={false} className="form-control" onChange={(e)=>{setUser({...user,lastname:e.target.value})}} value={user.lastname as string}  id="formGroupExampleInput" placeholder="Last Name"/></Col>
  <Col></Col> </Row>
      <Row className="mt-3"> 
       <Col><label htmlFor="formGroupExampleInput" className="form-label">Type</label></Col>
        <Col>  
  <input type="text" disabled={true} className="form-control" value={user.role as string}  id="formGroupExampleInput"  placeholder="Type"/></Col>
     <Col></Col>
      </Row>
      <Row className="mt-3">
        <Col><label htmlFor="formGroupExampleInput" className="form-label">Email</label> </Col>
        <Col>  
  <input type="text" disabled={true} className="form-control" value={user.email as string}  id="formGroupExampleInput" placeholder="Email"/></Col>
  <Col></Col></Row >
        </div>
        <Row>
        <Col className="d-flex justify-content-center mt-3">
        <button type="button" 
         onClick={()=>{
          
            authService.update_profile(user.lastname as string,user.firstname as string)
            .then((data)=>{if(data.msg=="success"){setcolor('green');setMessage("Profile Updated Successfully");setOpen(true)}else{
                setcolor('red');setMessage(data.msg);setOpen(true)
            }})}} style={{width:"30%"}} className="btn btn-primary">Update Profile</button>
        </Col>
   
    </Row>
       <div style={{'marginTop':40}}>
        <Row>
            <Col className="d-flex justify-content-end"> <Button onClick={()=>{setChangepassword(!changepassword)}}>Update Your Password</Button>  </Col>    
        </Row>
       
      
        <div style={{'marginTop':20}}>
        </div>
        <Row>   <Col> <hr style={{ 'color': 'black' }} />  </Col>  </Row>        
        <Row>
            <Col>
          

                {changepassword?  <Card body>
             <Row>
                <Col style={{ marginTop:30,marginBottom:30}}><Row>
                    <Col>
                    <Row className="m-3">
                        <Col>
                        <input
          type="text"
          name="oldpassword"
          className="form-control"
          placeholder='Enter Old password'
          value={input.oldpassword}
          onChange={onInputChange}
          onBlur={validateInput}></input>
          <div>
          {error.oldpassword && <span className='text-danger'>{error.oldpassword}</span>}
          </div>
        

                        </Col>
                    </Row>
                  <Row className="m-3">
                    <Col>
                    <input
          type="password"
          name="password"
          className="form-control"
          placeholder='Enter Password'
          value={input.password}
          onChange={onInputChange}
          onBlur={validateInput}></input>
        <div>
        {error.password && <span className='text-danger'>{error.password}</span>}
        </div>
        
                    </Col>
                  </Row>
 
        <Row className="m-3">
            <Col>
            <input
          type="password"
          name="confirmPassword"
          className="form-control"
          placeholder='Enter Confirm Password'
          value={input.confirmPassword}
          onChange={onInputChange}
          onBlur={validateInput}></input>
        <div>
        {error.confirmPassword && <span className='text-danger'>{error.confirmPassword}</span>}
        </div>
        
            </Col>
        </Row>
 
       
    <Row>
        <Col className="d-flex justify-content-center">
        <button type="button" disabled={error.confirmPassword||error.oldpassword||error.confirmPassword?true:false}
         onClick={()=>{
          console.log(input)
            authService.update_profile_password(input.password,input.confirmPassword,input.oldpassword)
            .then((data)=>{if(data.msg=="success"){setcolor('green');setMessage("Password Updated Successfully");setOpen(true)}else{
                setcolor('red');setMessage(" Wrong Old Password ");setOpen(true);
            }})}} style={{width:"30%"}} className="btn btn-primary">Update password</button>
        </Col>
   
    </Row>
        
                     </Col>
                 </Row> </Col>
             
             </Row>
           
          
            </Card>: <div></div> }


               
            </Col>
        </Row>
       
        </div> 

      
     
    </Card></Col> 
    </Row>
   
    <CustomizedSnackbars color={color} open={open} message={message}  handelclose={handelcolse}></CustomizedSnackbars>
</Container>

    </div> 
}