
import * as React from 'react';

import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';

import Avatar from '@mui/material/Avatar';

import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import authService from '../../services/Auth_service';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import PeopleIcon from '@mui/icons-material/People';

const settings = ['Logout'];
 export default function UserSettings(){
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };
    return <>
      <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} >
                <Avatar style={{width:"50px",height:"50px",marginLeft:"5px"}} alt="Remy Sharp" src="/userimage.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              
                <MenuItem key={2} onClick={handleCloseUserMenu}>
                <Button  onClick={()=>{navigate("/Profile")}}> < AccountBoxIcon></ AccountBoxIcon> <span style={{color:"black",marginLeft:"3px"}}>Profile</span> </Button>  
                </MenuItem>
               
                <MenuItem key={3} >
                <Button onClick={()=>{navigate('/statistics')}}> <SignalCellularAltIcon></SignalCellularAltIcon> <span style={{color: "black",marginLeft:"3px"}}>Statistics</span> </Button>  
                </MenuItem>
               
                <MenuItem key={3} >
                <Button onClick={()=>{authService.logout();navigate(0);}}> <LogoutIcon></LogoutIcon> <span style={{color: "black",marginLeft:"3px"}}>Logout</span> </Button>  
                </MenuItem>
          
            </Menu> 
     
           </>
        
}
