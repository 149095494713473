
import axios from "axios";
import { Item } from "../models/item";
import { items } from "../models/items";

const url ="https://api.coralytics.com/"
  /***************************************************************************** */
  const GetItems = async (id:string) => {
   
    const response = await axios
          .post(url+"list_items", {
             
          },{
            headers: {
              "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
            }
          });
       
      var items:items = {found_items:response.data.found_items} as items;
         console.log(items);
      return items;
  };

  /*********************************************************************************** */
  const GetItem = async(iduser:string,iditem:string)=>{
   const response = await axios
   .post(url+"get_item", {
     
      "item_id": iditem,
   },{
      headers: {
        "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
      }
    });
    var i : Item ={found_item:response.data.found_item,msg:response.data.msg} as Item;
    console.log(i);
    return i;
  }
  /************************************************************************************************* */
  const GetItemsbyfilter = async(iduser:string,filter_by:string,iditem:number,page:number,datestart:any,dateend:any,duplicate:string)=>{
     const dup:boolean = JSON.parse(duplicate);
    const response = await axios
    .post(url+"list_items", {
       
       "item_id": iditem==null?0:iditem,
       "datestart":datestart,
       "filter_by":filter_by,
       "dateend":dateend,
       "has_duplicates":dup,
       "page":page,
       "pagecount":10
    },{
      headers: {
        "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
      }
    });
  
    return response.data;
   }
   const GetItemId = async(iduser:string,index:number)=>{
      const response = await axios
      .post(url+"get_manual_queue_index", {
        
         "item_index": index,
      },{
         headers: {
           "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
         }
       });
      
       return response.data;
     }
     const GetItemjustId = async(iduser:string,index:number)=>{
      const response = await axios
      .post(url+"get_manual_queue_index", {
        
         "item_index": index,
      },{
         headers: {
           "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
         }
       });
      
       return response.data.item_id as string;
     }
     /****************************************************************** */
     const GetAgentitems = async(iduser:string,filter_by:string,iditem:number,page:number,datestart:any,dateend:any,agentid:number,duplicate:boolean)=>{
      const response = await axios
      .post(url+"list_items_by_agent", {
         
         "item_id": iditem==null?0:iditem,
         "datestart":datestart,
         "filter_by":filter_by,
         "dateend":dateend,
         "page":page,
         "pagecount":10,
         "has_duplicates":duplicate,
         "agent_id":agentid
      },{
         headers: {
           "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
         }
       });
      
       return response.data;
     }
     /***************************************get_listing_applied_rules
[{
rule_name: string,
rule_decision: string
}] */
const Get_listing_applied_rules = async(iduser:string,index:number)=>{
   const response = await axios
   .post(url+"get_listing_applied_rules", {
    
      "item_index": index,
   },{
      headers: {
        "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
      }
    });
   
    return response.data;
  }
const Itemservice={
  GetItem,GetItems,GetItemsbyfilter,GetItemId,GetAgentitems,GetItemjustId,Get_listing_applied_rules
};
export default Itemservice;

