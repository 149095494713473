import TextField from "@mui/material/TextField/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Autocomplete from "@mui/material/Autocomplete";
import Switch from "@mui/material/Switch";
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

function Searchtable(props:{fliter:string,changedulicate:any,hasduplicates:any,updatefilter:any,changedateend:any,changedatestart:any,itemid:any,updateitemid:any,dateend:any,datestart:any}){
    const [datestart, setDatestart] = useState(props.datestart.split('/')[1]+"/"+props.datestart.split('/')[0]+"/"+props.datestart.split('/')[2]);
    const [dateend, setDateend] = useState(props.dateend.split('/')[1]+"/"+props.dateend.split('/')[0]+"/"+props.dateend.split('/')[2]);
    const [filter, setFilter] = useState("All");
    const [searchParams] = useSearchParams();
    const [agentid,setAgentid]=useState(0)
    const fliters = ["Approved","Refused","Manual","No Decision","All"];
    
    useEffect(()=>{
      setAgentid(Number.parseInt(searchParams.get('itemid')as string))
    },[])
  const handleChangestart = (newValue) => {
      setDatestart(newValue);
    const m = Number.parseInt(newValue.$M)+1;
    props.changedatestart(newValue.$D+'/'+m+'/'+newValue.$y)
  };
  const handleChangeend = (newValue) => {
    setDateend(newValue);
    const m = Number.parseInt(newValue.$M)+1;
    props.changedateend(newValue.$D+'/'+m+'/'+newValue.$y)
   
  };

    return <div style={{marginTop:30,marginBottom:30,marginLeft:10}}>
      <Row>
        <Col><h3>Recent Items </h3></Col>
    </Row><Row className="mt-5">

        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Col>
          
                <DesktopDatePicker
          label="Date start"
          
          inputFormat="DD/MM/YYYY"
          value={props.datestart.split('/')[1]+"/"+props.datestart.split('/')[0]+"/"+props.datestart.split('/')[2]}
          onChange={handleChangestart}
          renderInput={(params) => <TextField {...params} fullWidth={true} />}
        /></Col>
        <Col><DesktopDatePicker
          label="Date end"
          inputFormat="DD/MM/YYYY"
          value={props.dateend.split('/')[1]+"/"+props.dateend.split('/')[0]+"/"+props.dateend.split('/')[2]}
          onChange={handleChangeend}
          renderInput={(params) => <TextField {...params} fullWidth={true} />}
        />
         
        </Col>
        <Col>
        <TextField fullWidth={true} value={agentid} autoComplete='off' type='number' label="Item id" onChange={(value)=>{
          setAgentid(parseInt(value.target.value))
          props.updateitemid(value.target.value==''?0:Number.parseInt(value.target.value));
        }}  placeholder="search by item id"></TextField>
        </Col>
        <Col> <Autocomplete
                     onChange={(event, newValue)=>{props.updatefilter(newValue || '')}}
                                    disablePortal
                                    id="combo-box-d"
                                    value={props.fliter}
                                    options={fliters}
                                  
                                    renderInput={(params) => <TextField {...params}  onChange={(value)=>{props.updatefilter(value.target.value)}}  label="--FLITER BY--" />} /></Col>
        </LocalizationProvider>
        <Col>
        
        <Col>
        <Row>
          <Col style={{ verticalAlign:"middle"}}> Duplicates </Col>
         
        </Row>
        <Row>
        <Col style={{ verticalAlign:"middle"}}>   <Switch onChange={()=>{props.changedulicate(!props.hasduplicates)}} checked={props.hasduplicates}></Switch> </Col>
        </Row>
      
        </Col>
        </Col>
        
        </Row>
        </div>
}
export default Searchtable;
