import React, { useState,useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Chart
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";
import { ClockNumberClassKey } from "@mui/x-date-pickers";
import { Hidden, IconButton } from "@mui/material";
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import { height } from "@mui/system";
import { title } from "process";
import { ChartData } from "../../models/chartdata";
import authService from "../../services/Auth_service";
import Dashbordservice from "../../services/dashbordService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "All Decision",
      LineTension:10,
      data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
      borderColor: "#0CA8F2",
      backgroundColor: "#0CA8F2",
      yAxisID: "y"
    },
    {
      label: "Manual",
      LineTension:10,
    
      data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
      borderColor: "rgb(53,221,214)",
      backgroundColor: "rgb(53,221,214)",
      yAxisID: "y1"
    },
    {
      label: "Refused",
      LineTension:10,
     
      data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
      borderColor: "#F83A5B",
      backgroundColor: "#F83A5B",
      yAxisID: "y"
    },
    {
      label: "No action",
      LineTension:10,
      data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
      borderColor: "#7E6CF0",
      backgroundColor: "#7E6CF0",
      yAxisID: "y"
    }
  ]
};
export const data2 = {
  labels,
  datasets: [
    {
      label: "All Decision",
      LineTension:10,
      data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
      borderColor: "rgb(53,221,214)",
      backgroundColor: "rgb(53,221,214)",
      yAxisID: "y"
    },
   
    {
      label: "No action",
      LineTension:10,
      data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
      borderColor: "rgb(20,116,210)",
      backgroundColor: "rgb(25,117,210)",
      yAxisID: "y"
    }
  ]
};

export default function Doublelinechart(props:{title:string,type:number,data:any}) {
  const[toshow,setToshow]= useState("Manual");
  const[dataset,setDataset] =useState(data);
   const [loading,setLoading]=useState(true)
  const [chartdata,setChartdata]=useState<any>();

  useEffect(()=>{
    Dashbordservice.Getoverview(authService.getCurrentUser(),toshow).then((data)=>{
        
       if(data.datasets){
        setChartdata(data)
       }
     
  }).then(()=>{
   
        setLoading(false)
      })
  },[toshow])
 

   const show=(v)=>{
       setToshow(v);
      
       
   }
    
    const options = {
        elements: {
            line: {
                tension: 0.3 
            }
        },
        
      
       
        responsive: true,
        interaction: {
          mode: "index" as const,
          intersect: true,
          
        },
        stacked: false,
        plugins: {
          legend: {
            display:false,
            position: "left" as const,
            onClick: function (evt, item) {
              // do something
              Chart.defaults.plugins.legend.onClick.call(evt,evt, item,item);
            
             
             
           },
            
          },
          title: {
            display: false,
            text: props.title,
            
            padding: {
              top: 10,
              bottom: 10,
              align:'start',
              
              
          }
          
          }
        },
        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            grid: {
              display: false
            }
          }
       
        }
      };
  return chartdata? (props.type==1?<div>
    <div className="row">
      <div className="Col d-flex justify-content-center">
         <span>Overall Insight</span>
      </div>
    </div>
      <div className="row">
       <div className="col">
       <IconButton  aria-label="All Decision" style={{backgroundColor:"#DCDCDC"}}>
     <LegendToggleIcon htmlColor="rgb(53,221,214)"/><span style={{fontSize:14}}>All Decision</span> 
     </IconButton>
     <IconButton onClick={()=>{show("No action")}} aria-label="No action" style={toshow=="No action"?{backgroundColor:"#DCDCDC"}:{}}>
     <LegendToggleIcon htmlColor="rgb(25,117,210)"/><span style={{fontSize:14}}>No action</span> 
     </IconButton>
     <IconButton onClick={()=>{show("Refused")}} aria-label="Refused" style={toshow=="Refused"?{backgroundColor:"#DCDCDC"}:{}}>
     <LegendToggleIcon htmlColor="#F83A5B"/><span style={{fontSize:14}}>Refused</span> 
     </IconButton>
     <IconButton onClick={()=>{show("Manual")}} aria-label="Manual" style={toshow=="Manual"?{backgroundColor:"#DCDCDC"}:{}}>
     <LegendToggleIcon htmlColor="rgb(53,221,214)"/><span style={{fontSize:14}}>Manual</span> 
     </IconButton>
       </div>
      </div>
      <div className="row">
      <div className="col">
      <Line style={{backgroundColor:"white"}} options={options} data={loading==false?chartdata:data2} />
      </div>
      </div>
     
     
    </div> :  <Line style={{backgroundColor:"white"}} options={options} data={props.data==undefined?data2:props.data} /> ): 
  <div>
    {props.type==1?(<><div className="row">
        <div className="Col d-flex justify-content-center">
          <span>Overall Insight</span>
        </div>
      </div><div className="row">
          <div className="col">
            <IconButton aria-label="All Decision" style={{ backgroundColor: "#DCDCDC" }}>
              <LegendToggleIcon htmlColor="rgb(53,221,214)" /><span style={{ fontSize: 14 }}>All Decision</span>
            </IconButton>
            <IconButton onClick={() => { show("No action"); } } aria-label="No action" style={toshow == "No action" ? { backgroundColor: "#DCDCDC" } : {}}>
              <LegendToggleIcon htmlColor="rgb(25,117,210)" /><span style={{ fontSize: 14 }}>No action</span>
            </IconButton>
            <IconButton onClick={() => { show("Refused"); } } aria-label="Refused" style={toshow == "Refused" ? { backgroundColor: "#DCDCDC" } : {}}>
              <LegendToggleIcon htmlColor="#F83A5B" /><span style={{ fontSize: 14 }}>Refused</span>
            </IconButton>
            <IconButton onClick={() => { show("Manual"); } } aria-label="Manual" style={toshow == "Manual" ? { backgroundColor: "#DCDCDC" } : {}}>
              <LegendToggleIcon htmlColor="rgb(53,221,214)" /><span style={{ fontSize: 14 }}>Manual</span>
            </IconButton>
          </div>
        </div>   <div style={{textAlign:"center",marginTop:"50px"}}>
    
    <h5>No Data Available</h5>
   </div></>):(
     <div style={{textAlign:"center",marginTop:"30px",marginLeft:"3px",marginBottom:"20px"}}>
    
    <h5 style={{height:"60px"}}>No Data Available</h5>
   </div>)}

  </div>
 
}
