import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import Link from "@mui/material/Link";
import { Decision_rules } from '../../models/Decision_rules';
import Ruleservice from '../../services/RuleGestion';
import authService from '../../services/Auth_service';
import CircularProgress from "@mui/material/CircularProgress";
import Switch from '@mui/material/Switch';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CustomizedSnackbars from './Sncackbar';

const label = { inputProps: { 'aria-label': 'Switch demo' } };
   

export default function DataGridDemo() {
   const [Listrules,setListrules]=React.useState([]);
   const [loading,setLoading]=React.useState(true);
   const [active,setActive]=React.useState(true);
   const [open,setOpen]=React.useState(false);
   const [message,setMessage]=React.useState("");
   const [color,setcolor]=React.useState("green");
   const navigate = useNavigate();
  
   async function name() {
    const list = await Ruleservice.GetRules(authService.getCurrentUser());
    
     return list.data;
   } 
   const handelcolse =()=>{
    setOpen(false);
    if(color!="red"){
        navigate(0);
    }
   }
   
  React.useEffect( ()=>{
   name().then((json) => {setListrules(json);setLoading(false)});
  
       
  },[active])
  return ( <div>
     {loading ?  <CircularProgress /> :  <div className="container-fluid">

<div className="row">
    <div className="col-12">
    <div style={{ justifyContent:"flex-start",display:"flex",marginBottom:10}}>
         <h3>
            Rules List
         </h3>
        </div>
        <div style={{ justifyContent:"flex-end",display:"flex",marginBottom:10}}>
          <a style={authService.getCurrentuserRole()=="spectateur"?{pointerEvents:'none'}:{}} href="/newrule" className='btn btn-primary'>
            Add New Rule
          </a>
        </div>
        <div className="card m-b-20">
            <div className="card-body">
                <table id="datatable" className="table table-striped dt-responsive nowrap table-vertical" width="100%" cellSpacing="0">
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th> Name</th>
                           
                            <th>Creation Date</th>
                            <th>Action Type</th>
                            <th> Action reason</th>
                            <th> Actions</th>
                          
                          
                        </tr>
                    </thead>
                    <tbody>
                        {Listrules.map((item:any)=>{
                            return                                          <tr>
                            <td className="product-list-img">
                             <Switch disabled={authService.getCurrentuserRole()=="spectateur"} {...label} checked={item.active} onChange={()=>{
                               
                              Ruleservice.Activerule(authService.getCurrentUser(),item._id,!item.active).then( ()=> navigate(0));
                             }}  color='success' />
                             
                           
                           
                            </td>
                            <td>
                                <h6 className="mt-0 m-b-5">{item.rule_name}</h6>
                                <p className="m-0 font-14">{item.rule_description}</p>
                            </td>
                           
                            <td>{item.creation_date}</td>
                            <td>{item.rule_action_type}</td>
                            <td>
                              {item.rule_action_reason}
                            </td>
                            <td>
                                <a  style={authService.getCurrentuserRole()=="spectateur"?{pointerEvents:'none'}:{}}  className='btn btn-danger' href="#"  onClick={()=>{
                                        Ruleservice.deleteRule(authService.getCurrentUser(),item._id).then((data)=>{
                                           if(data.msg=="still_processing"){
                                            setMessage("this rule cannot be deleted until processing is finished");
                                           
                                            setcolor("red");
                                            setOpen(true);
                                            
                                           }else{
                                            setMessage("the rule was deleted");
                                            setcolor("green");
                                            setOpen(true);
                                           }
                                        })
                                }}> <DeleteIcon sx={{width:"15px",height:"15px"}}></DeleteIcon> </a>
                           
                           
                                <a   style={{marginLeft:5}} className='btn btn-primary' href={"/ruledetails/"+item._id}> <RemoveRedEyeIcon sx={{width:"15px",height:"15px"}} ></RemoveRedEyeIcon> </a>
                            </td>
                        </tr>

                        })}


                    
                       
                    </tbody>
                </table>
            </div>
            <CustomizedSnackbars color={color} open={open} message={message}  handelclose={handelcolse}></CustomizedSnackbars>
        </div>
    </div>
</div>
</div>  }
  </div>
  
  );
}
