import { Autocomplete, Divider, TextField } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Accepted, list_invites, Pending } from "../../models/List_Invit";
import { User } from "../../models/User";
import authService from "../../services/Auth_service";
import Inviteservice from "../../services/Inviteservice";

function Teammembers(){

    const roles=["admin","spectateur","automation specialist"];
    const [listinvits,setListinvits]=useState<list_invites>();
     useEffect(()=>{

     Inviteservice.GetINvites(authService.getCurrentUser()).then((data)=>{
          setListinvits({accepted:data.accepted,msg:data.msg,pending:data.pending})
     }).then(()=>{console.log(listinvits)});

     },[]);
  
    return <div style={{marginTop:30}}>
          
   
    { listinvits?.accepted.map((user: Accepted) =>{
               return <><Row>
                   <Col>
                       <ListItem>
                           <ListItemText primary={user.first_name} secondary={user.invitee_email} />
                       </ListItem>
                   </Col>
                   <Col>
                       <Autocomplete
                       disabled={authService.getCurrentuserRole()=="spectateur"}
                           disablePortal
                           id="combo-box-demo"
                           options={roles}
                           value={user.invitee_role}
                           sx={{ width: 300 }}
                           renderInput={(params) => <TextField {...params} label="Role" />} />
                   </Col>
               </Row><Row>
                       <Divider></Divider>
                   </Row></>
                
            })}
         <Row style={{marginTop:30}}>
            <Col><h4>Pending Invites:</h4> </Col>
         </Row>
         { listinvits?.pending.map((user: Pending) =>{
       return <> <Row className="mt-2">
            <Col>
             <Row>
                <Col><span>{user.invitee_email}</span></Col>
             </Row>
             <Row>
                <Col><span>Invitation sent <a href="#">Send a reminder</a> </span></Col>
             </Row>
            </Col>
            <Col>
            <Autocomplete
            disabled={authService.getCurrentuserRole()=="spectateur"}
                           disablePortal
                           id="combo-box-demo"
                           options={roles}
                           value={user.invitee_role}
                           sx={{ width: 300 }}
                           renderInput={(params) => <TextField {...params} label="Role" />} />
            </Col>
         </Row></>
         })}
         
         
</div>


}
export default Teammembers;