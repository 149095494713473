import Autocomplete from "@mui/material/Autocomplete";

import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import { Col, Row,Button } from "react-bootstrap";
import emailjs from 'emailjs-com'
import { useEffect, useState } from "react";
import { User } from "../../models/User";
import Teammembers from "./team_mermbers";
import Inviteservice from "../../services/Inviteservice";
import { InviteUser } from "../../models/InviteUser";
import authService from "../../services/Auth_service";
import CustomizedSnackbars from "../rules/Sncackbar";


 

 function Invituser(){
    let listuser:User[]=[
        {id:"1",fullname:"hassan feyd",email:"hassanfeyd13@gmail.com",password:"shanksroro",role:"Admin"},
        {id:"2",fullname:"mouad elgourud",email:"mouadelgourid@gmail.com",password:"shanksroro",role:"Admin"},
        {id:"3",fullname:"Robert leva",email:"robertleva@gmail.com",password:"shanksroro",role:"Automation specialist"},
        {id:"4",fullname:"luca modric",email:"luca_modric13@gmail.com",password:"shanksroro",role:"moderator"}

    ]
    const roles=["admin","spectateur","automation specialist"];
    const [user,setUser]=useState<User[]>(listuser);
    const [open,setOpen]=useState<boolean>(false);
    const handelcolse =()=>{
        setOpen(false);
       }
    const [invite,setInvite]=useState<InviteUser>({invitee_email:'',invitee_role:'',user_id:authService.getCurrentUser()});
  
         return <Container  style={authService.getCurrentuserRole()=="spectateur"?{pointerEvents:'none'}:{}}>
        <Row >
            <Col>
               <h3>User Settings</h3>
            </Col>
           
        </Row>
        <Row className="mt-3 ">
        <Col>
               <h5>Invite User to the Team </h5>
            </Col>
        </Row>
       
        <Row style={{marginTop:30}}>
           
          
            <Col>
            <TextField disabled={authService.getCurrentuserRole()=="spectateur"} fullWidth={true} value={invite.invitee_email} onChange={(text)=>{setInvite((prevState) => ({
          ...prevState,
          invitee_email:text.target.value
        }))}} placeholder="Email to invite" label="Email" ></TextField>
            </Col>
            <Col>
            <Autocomplete
            disabled={authService.getCurrentuserRole()=="spectateur"}
            disablePortal
            fullWidth={true}
            onChange={(event, newValue)=>{setInvite((prevState) => ({
                ...prevState,
                invitee_role:newValue || ''
              }))}}
            id="combo-box-demo"
          options={roles}
          
          renderInput={(params) => <TextField  {...params} label="Select Role" />}
/>
            </Col>
            <Col className="justify-content-center mt-2">
             <Button disabled={authService.getCurrentuserRole()=="spectateur"}  onClick={()=>{Inviteservice.AddInvite(invite).then(()=>{setOpen(true)})}} style={{width:"100px"}}>Invite</Button>
            </Col>
           
        </Row>
       
        <Row>
            <Col lg={4}>
            <Row style={{marginTop:30}}>
            <Col><h4>Team Settings</h4></Col>
           </Row>
           <Row style={{marginTop:30}}>
            <Col ><span > Team Members </span></Col>
          
        </Row>
      
       
            </Col>
            <Col>
            <Row>
            <Col><Teammembers/></Col>
        </Row>
            </Col>
        </Row>
      
        <CustomizedSnackbars open={open} message='the Invite was added successfully' color="green" handelclose={handelcolse}></CustomizedSnackbars>
       
    </Container>

 }
 export default Invituser;