
import axios from "axios";
import { GetAction } from "../models/GetAction";
import { SetAction } from "../models/SetAction";

const url ="https://api.coralytics.com"
const Setaction = async (action:SetAction) => {
    const response = await axios
          .post(url+"/set_listing_action", {

            "listing_id": action.listing_id,
            "listing_action_name": action.listing_action_name,
            "listing_action_reason": action.listing_action_reason,
            "listing_action_type": action.listing_action_type,
          
          },{
            headers: {
              "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
            }
          });
          console.log(response.data)
      return response.data;
  };

  /***************************************************************************** */
  const Getaction = async (iduser:string,iditem:string) => {
   
    const response = await axios
          .post(url+"/get_listing_action", {
            "listing_id": iditem,
            
          },{
            headers: {
              "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
            }
          });
       
         var i : GetAction = {listing_action:response.data.listing_action,msg:response.data.msg} as  GetAction;
         console.log(i);
      return i;
  };

  /*********************************************************************************** */
const Actionservice={
   Setaction,Getaction
};
export default Actionservice;

