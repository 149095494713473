
import axios from "axios";


const url ="https://api.coralytics.com"
const SetDuplicates = async (duplicate_location:string,duplicate_time:string,image_score:number,description_score:number,title_score:number,image_checked:boolean,description_checked:boolean,title_checked:boolean,user_id:string,isactive:boolean) => {
    const response = await axios
          .post(url+"/set_duplicates", {

            "duplicate_location":duplicate_location,
            "duplicate_time":duplicate_time,
            "image_score":image_score,
             "description_score":description_score,
             "title_score":title_score,
             "image_checked":image_checked,
            "description_checked":description_checked,
            "title_checked":title_checked,
          
            "isactive":isactive
          },{
            headers: {
              "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
            }
          });
          console.log(response.data)
      return response.data;
  };

  /***************************************************************************** */
  const GetDuplicates = async (iduser:string) => {
   
    const response = await axios
          .post(url+"/get_duplicates", {
            
          },{
            headers: {
              "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
            }
          });
     
       
      return response.data;
  };
  /*********************************************************************************** */
  const changedublicatestatus = async (iduser:string,isactive:boolean) => {
   
    const response = await axios
          .post(url+"/change_duplicates_status", {
            
             "isactive":isactive
          },{
            headers: {
              "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
            }
          });
     
       
      return response.data;
  };
  /***************************************************************** */
const Duplicateservice={
   SetDuplicates,GetDuplicates,changedublicatestatus
};
export default Duplicateservice;

