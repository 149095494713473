import InitialState, { UpdateCounterAction } from "./types/counter";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import authService from "../services/Auth_service";
import Itemservice from "../services/ItemsService";

 
const initialState: InitialState = {
  has_next: false,
  has_prev:false,
  itemid:"test",
  page:0,
  paginationpage:1
};

export const counterSlice = createSlice({
  name: UpdateCounterAction,
  initialState: initialState,
  reducers: {
   
    updatehasnext: (state, action: PayloadAction<boolean>) => {
      state.has_next = action.payload;
    },
    
    updatepaginationpage: (state, action: PayloadAction<number>) => {
      state.paginationpage = action.payload;
    },
    
    
    updatehasprev: (state, action: PayloadAction<boolean>) => {
      state.has_prev = action.payload;
    },
    updatepage: (state, action: PayloadAction<number>) => {
        state.page = action.payload;
      },
      updateitemid: (state, action: PayloadAction<string>) => {
        state.itemid = action.payload;
      },
  },
});

export const {  updatehasnext, updatehasprev,updateitemid,updatepage,updatepaginationpage } =
  counterSlice.actions;

export default counterSlice.reducer;