import { CircularProgress, Switch } from "@mui/material";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const Listaccounts=[{fullname:"First Image project",email:"first room description",role:"type 1",date:"2023-03-24"},
{fullname:"watermark project",email:"water mark description",role:"Type 2",date:"2023-03-24"},
{fullname:"All image Bathroom ",email:"All image bathrom description",role:"Typ é",date:"2023-03-24"},
{fullname:"item loacation",email:"loaction descrription",role:"type z",date:"2023-03-24"}
]
const loading=false;

function ListProject(){

    return <div>
    {loading ?  <CircularProgress /> :  <div className="container-fluid">

<div className="row">
   <div className="col-12">
   <div style={{ justifyContent:"flex-start",display:"flex",marginBottom:10}}>
        <h3>
           Projects List
        </h3>
       </div>
       <div style={{ justifyContent:"flex-end",display:"flex",marginBottom:10}}>
         <a  href="/createproject" className='btn btn-primary'>
           Add New Project
         </a>
       </div>
       <div className="card m-b-20">
           <div className="card-body">
               <table id="datatable" className="table table-striped dt-responsive nowrap table-vertical" width="100%" cellSpacing="0">
                   <thead>
                       <tr>
                        <th>Status</th>
                           <th> Project Name</th>
                           <th>Description </th>
                           <th>Creation date</th>
                           <th>Project type</th>
                           <th>Details</th>
                         
                         
                       </tr>
                   </thead>
                   <tbody>
                       {Listaccounts.map((item:any)=>{
                           return                                          <tr>
                           <td className="product-list-img">
                            <Switch  checked={true} onChange={()=>{
                              

                            }}  color='success' />
                            
                          
                          
                           </td>
                           <td>
                               <h6 className="mt-0 m-b-5">{item.fullname}</h6>
                               
                           </td>
                          
                           <td>{item.email}</td>
                           <td>{item.date}</td>
                           <td>{item.role}</td>
                         
                           <td>
                          
                               <a   style={{marginLeft:5}} className='btn btn-primary' href={"/ruledetails/"+item._id}> <RemoveRedEyeIcon sx={{width:"15px",height:"15px"}} ></RemoveRedEyeIcon> </a>
                           </td>
                       </tr>

                       })}


                   
                      
                   </tbody>
               </table>
           </div>
        
       </div>
   </div>
</div>
</div>  }
 </div>
}
export default ListProject;