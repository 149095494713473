import { Console } from "console";
import { useEffect, useState } from "react"
import ImageGallery from 'react-image-gallery';


const imagesl = [
    {
      original: 'https://content.rolex.com/dam/watches/family-pages/gmt-master-ii/gmt-master-ii-m126711chnr-0002-share.jpg',
      thumbnail: 'https://content.rolex.com/dam/watches/family-pages/gmt-master-ii/gmt-master-ii-m126711chnr-0002-share.jpg',
    },
    {
      original: 'https://content.rolex.com/dam/watches/family-pages/gmt-master-ii/all-models/watches-share_gmt-master2_m126710blro_0001_1809jva_001.jpg',
      thumbnail: 'https://content.rolex.com/dam/watches/family-pages/gmt-master-ii/all-models/watches-share_gmt-master2_m126710blro_0001_1809jva_001.jpg',
    },
    {
      original: 'https://www.montres-de-luxe.com/photo/art/grande/63534969-45778144.jpg?v=1649061370',
      thumbnail: 'https://www.montres-de-luxe.com/photo/art/grande/63534969-45778144.jpg?v=1649061370',
    },
  ];
 
  
function Images(props:{images:any}){
      const [images,setImages]=useState<any[]>([]);
      const [lo,setLo]=useState(true)
       useEffect(()=>{
        props.images.map((m)=>{
            images.push({"original":m.src,"thumbnail":m.src})
        })
         setImages(images);
         setLo(false)
         
       },[]);
    return (lo?<div>
        loadin ..
        </div>:<div>
          <ImageGallery  items={images}></ImageGallery>
    </div>)
}
export default Images;