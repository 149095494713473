import { Console } from "console";
import { useEffect, useState } from "react"
import ImageGallery from 'react-image-gallery';



 
  
function ImagesCol(props:{images:any}){
      
    return (<div >
          <ImageGallery  items={props.images.map((m)=>({"original":m.src,"thumbnail":m.src}))}></ImageGallery>
    </div>)
}
export default ImagesCol;