interface InitialState {
    has_next: boolean;
    has_prev:boolean;
    itemid:string;
    page:number;
    paginationpage:number
  }
  const UpdateCounterAction: string = "Counter";
  
  export default InitialState;
  export { UpdateCounterAction };