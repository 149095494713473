import React , {Component, useEffect, useState } from 'react';
import Searchtable from './Search_table';
import ItemsService from '../../services/ItemsService';
import authService from '../../services/Auth_service';

import { items } from '../../models/items';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button/Button';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import LinearProgress from '@mui/material/LinearProgress';import { RootState } from "../../redux/Store";
import { useDispatch, useSelector } from "react-redux";

import {
  updatepaginationpage
} from "../../redux/counterSlice";

import dayjs from 'dayjs';
import { numberToString } from '@amcharts/amcharts5/.internal/core/util/Type';
const timeFormat: Intl.DateTimeFormatOptions = {   timeZone: 'UTC+1' }
function Ecommerce_product_list() {
    const [searchParams] = useSearchParams();
console.log(searchParams.get('page')as string+searchParams.get('datestart')as string);
    const [items2,setItems2]=useState<items>();
    const [loading,setLoading]=useState(true);
    const [filter, setFilter] = useState("All");
    const [has_data,setHas_data]=useState(true);
    const [itemid, setItemid] = useState<number>(0);
    const pagepagination: number = useSelector(
        (state: RootState) => state.counter.paginationpage
      );
      const dispatch = useDispatch();
    const changeitemid=(value)=>{
        navigate("/items?page=1&datestart="+datestart+"&dateend="+dateend+"&has_duplicates="+duplicate+"&filter="+filter+"&itemid="+value);
        setTimeout(() => {
            navigate(0)
          }, 3000);
       
    }
    let {pageid} = useParams();
    const [page, setPage] = React.useState(pagepagination);
    const [pagecount, setPagecount] = React.useState<number | undefined>(1);
    const [datestart, setDatestart] = useState("");
    const [dateend, setDateend] = useState("");
    const [duplicate,setDuplicate]=useState(false);
    const changeduplicate=(value)=>{
        
        navigate("/items?page=1&datestart="+datestart+"&dateend="+dateend+"&has_duplicates="+value+"&filter="+filter+"&itemid="+itemid);
        navigate(0);
       }
     const  changedateend =(value)=>{
        navigate("/items?page=1&datestart="+datestart+"&dateend="+value+"&has_duplicates="+duplicate+"&filter="+filter+"&itemid="+itemid)
     
       navigate(0)
     }
     const  changedatestart =(value)=>{
        navigate("/items?page=1&datestart="+value+"&dateend="+dateend+"&has_duplicates="+duplicate+"&filter="+filter+"&itemid="+itemid)
       
        navigate(0)
        
       }
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        dispatch(updatepaginationpage(value));
         
        navigate("/items?page="+value+"&datestart="+datestart+"&dateend="+dateend+"&has_duplicates="+duplicate+"&filter="+filter+"&itemid="+itemid)
        navigate(0)
    };
    const updatefilter =(value:string)=>{
        console.log(value);
         
           navigate("/items?page=1&datestart="+datestart+"&dateend="+dateend+"&has_duplicates="+duplicate+"&filter="+value+"&itemid="+itemid)
           navigate(0)
    }
    const navigate = useNavigate();
    /***************************************** */
    useEffect(()=>{
         
    ItemsService.GetItemsbyfilter(authService.getCurrentUser(),searchParams.get('filter')as string,Number.parseInt(searchParams.get('itemid')as string),Number.parseInt(searchParams.get('page')as string),searchParams.get('datestart')as string,searchParams.get('dateend')as string,searchParams.get('has_duplicates')as string).then((data)=>{
        setItems2({found_items:data.found_items,page_count:data.page_count});
           if(data.found_items.length==0){
            setHas_data(false)
           }else{
            setHas_data(true)
           }
        
                setDatestart(searchParams.get('datestart')as string);
                setDateend(searchParams.get('dateend')as string)
                setFilter(searchParams.get('filter')as string)
                setPage(Number.parseInt(searchParams.get('page')as string));
                setDuplicate(JSON.parse(searchParams.get('has_duplicates')as string))
                setItemid(Number.parseInt(searchParams.get('itemid')as string))
          
        
        
        setPagecount(data.page_count as number);
        
      
    }).then(()=>{setLoading(false)});
         

    },[])

 


    return (loading?<div className='m-30'>
             <LinearProgress />
            </div>:<div className="page-content-wrapper">
            <Searchtable changedulicate={changeduplicate} hasduplicates={duplicate} changedateend={changedateend} dateend={dateend} datestart={datestart}  changedatestart={changedatestart} itemid={itemid} updateitemid={changeitemid} fliter={filter} updatefilter={updatefilter}></Searchtable>
            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="card m-b-20">
                            <div className="card-body">
                                {has_data?
                                <table id="datatable" className="table table-striped dt-responsive nowrap table-vertical" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                           <th style={{textAlign:"center"}}>Image</th>
                                            <th style={{textAlign:"center"}}>Title</th>
                                            <th style={{textAlign:"center"}}>Id</th>
                                            <th style={{textAlign:"center"}}>Creation Date</th>
                                            <th style={{textAlign:"center"}}>Decision</th>
                                            <th style={{textAlign:"center"}}>Status</th>
                                            <th style={{textAlign:"center"}}>Versions Count</th>
                                            <th style={{textAlign:"center"}}> Duplicate </th>
                                            <th> </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items2?.found_items.map((item:any,index:number)=>{
                                            return              <tr>
                                            <td className="product-list-img">
                                                {item.content.images.length==0?<img style={{height:'100px',width:"200px"}} src={'/no_image.png'} className="img-fluid" alt="tbl" />: <img style={{width:"200px"}} src={item.content.images[0].src} className="img-fluid" alt="tbl" />}
                                               
                                            </td>
                                            <td style={{ verticalAlign:"middle",fontSize:17}}>
                                                
                                                {item.content.title}
                                            </td>
                                            <td style={{ verticalAlign:"middle",fontSize:17,textAlign:"center"}}>{item.id}</td>
                                            <td style={{ verticalAlign:"middle",fontSize:17,textAlign:"center"}}>{item.date_inserted}</td>
                                            <td style={{ verticalAlign:"middle",fontSize:17,textAlign:"center"}}>{item.taken_action_name}</td>
                                            <td style={{ verticalAlign:"middle",fontSize:17,textAlign:"center"}}>
                                              {item.content.status}
                                            </td>
                                            <td style={{ verticalAlign:"middle",textAlign:"center"}}>
                                                {item.versions_count}
                                            </td>
                                            <td style={{ verticalAlign:"middle",fontSize:17,textAlign:"center"}}>
                                              {item.has_duplicates?"Yes":"No"}
                                            </td>
                                            <td style={{ verticalAlign:"middle",fontSize:17,textAlign:"center"}}>
                                                <Button onClick={()=>{
                                                    navigate("/automation/"+item._id);
                                                }}>
                                                <VisibilityIcon ></VisibilityIcon>
                                                </Button>
                                            </td>
                                        </tr>

                                        })}


                                    
                                       
                                    </tbody>
                                </table>
                                : <div> <h2>No Data Was Found</h2> </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className='float-end mt-3 mb-10'>
            <Pagination size='large' count={pagecount} page={Number.parseInt(searchParams.get('page')as string)} onChange={handleChange} color="primary" />
            </div>
           
        </div>);
    
}

export default Ecommerce_product_list;   