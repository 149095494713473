import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField/TextField";
import { useContext, useEffect, useState } from "react";
import { Row,Col} from "react-bootstrap";
import authService from '../../services/Auth_service';
import Ruleservice from '../../services/RuleGestion';
import {  RuleCondition } from '../../models/Rule';
import * as React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";

import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from '@mui/icons-material/Delete';


function Conditions(props:{callback:any,deleterule:any,index:any}){
  
    const [valuetype,setVlauetype]=useState("non");
    const [values,setValues]=useState<string[]>([]);
    const [fields,setFields]=useState<string[]>([]);
    const [rule_conditions,setRule_conditions]=useState<Array<RuleCondition>>([]);
    const [type,setType]=useState('');
  const [condition,setCondition]=useState('');
  const [value,setValue]=useState('');
  const [x,setX]=useState('');
  const [y,setY]=useState('');
  const [vfree,setVfree]=useState('');
  const [operators,setOperators]=useState<string[]>([]);
  const [user_id,setUser_id]=useState("");
  const [showcondition,setShowcondition]=useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef<number>();
  const handleButtonClick = () => {
    if(valuetype=="multiple_text_entries"){
        let conds :RuleCondition={'condition':condition,'type':type,'value':x+','+y} as RuleCondition;
        props.callback(conds);
    }else{

        let conds :RuleCondition={'condition':condition,'type':type,'value':value} as RuleCondition;

        props.callback(conds);
    }

    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 2000);
    }
  };
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700]
      }
    })
  };
    useEffect(()=>{
      if(authService.Isconnected()){
        Ruleservice.Getfields(authService.getCurrentUser()).then((data)=>{
          if(data!=null){
            data.data.map((d)=>{
              fields.push(d);     
           });
           setFields(fields);
          } 
          else 
          alert("Erorr")
        
         });
         return () => {
             clearTimeout(timer.current);
           };
      }
       
  },[])
    return <><Row>
    <Col>     <hr style={{ 'color': 'black' }} />  </Col>
</Row><Row>
        <Col> <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={fields}
            onChange={(event, newValue)=>{
              setOperators([]);
              setCondition("");
              setVlauetype("");
              setShowcondition(false);
              setType(newValue || '');
               Ruleservice.Getoperators(authService.getCurrentUser(),newValue || '').then((data)=>{
                
                setOperators(data.data);
                
                console.log(data.data);
              
                 
               } ).then(()=>{setShowcondition(true);
               console.log(operators);});
          }}
           
            renderInput={(params) => <TextField {...params}  onChange={(text)=>{setType(text.currentTarget.value)}}  label="Types" />} /> </Col>
        <Col>
            <Autocomplete
                disablePortal
               
                id="combo-box-demo"
                 disabled={!showcondition}
                options={operators}
                value={showcondition?condition:""}
                onChange={(event, newValue)=>{setCondition(newValue || '');
                Ruleservice.Getvalues(authService.getCurrentUser(),type,newValue||'').then((data)=>{
                  console.log(data.data.select_type);
                  setVlauetype(data.data.select_type);
                  if(data.data.select_type=='list_select'){
                   setValues(data.data.values);
                    
                    
                  }
                }).then(()=>{console.log(valuetype);console.log(values);});
              }}
               
                renderInput={(params) => <TextField {...params} label="Choose Condition"   onChange={(text)=>{setCondition(text.currentTarget.value)}}/>} />
        </Col >
        {valuetype=="list_select"?  <Col  >
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                fullWidth={true}
                options={values}
                onChange={(event, newValue)=>{setValue(newValue || '')}}
               
                renderInput={(params) => <TextField {...params} label="Choose Value" fullWidth={true}  onChange={(text)=>{setValue(text.currentTarget.value)}}/>} />
        </Col>:valuetype=="text_entry"? 
        <Col >
            <TextField   onChange={(text)=>{setValue(text.currentTarget.value)}} label="Put your condition value" size='medium'  />
        </Col>:valuetype=="number_entry"? 
        <Col >
            <TextField value={value}  onChange={(text)=>{if(Number.parseInt(text.currentTarget.value) >=0 ){setValue(text.currentTarget.value)}else{setValue("0")}}} label="Put your condition value" type="number" size='medium' />
        </Col>:valuetype=="multiple_text_entries"? <><Col >
      <TextField   type={'number'} onChange={(text) => { setX(text.currentTarget.value); } } label="Enter  Min" size='medium' />
    </Col><Col > 
        <TextField type={'number'} onChange={(text) => { setY(text.currentTarget.value); } } label="Enter Max" size='medium' />
      </Col></>:valuetype=="specify_entry"?
       <>
      <Col>
      <Autocomplete
                disablePortal
                id="combo-box-demo"
                fullWidth={true}
                options={["Company Name","Free Text"]}
                onChange={(event, newValue)=>{setValue(newValue || '');setVfree(newValue || '')}}
                renderInput={(params) => <TextField {...params} label="Choose Value" fullWidth={true}  onChange={(text)=>{setValue(text.currentTarget.value)}}/>} />
      </Col>

      {vfree=="Free Text" && <Col ><TextField  fullWidth={true}  onChange={(text)=>{setValue(text.currentTarget.value)}} label="Put your condition value"  /></Col> }
      </>
        :
        <Col>  <TextField  type={'number'}    disabled={true}  fullWidth={true} /> </Col>
        }
       
        <Col  lg={2} md={2} sm="auto">
           
          {loading ? (
          <CircularProgress
            size={68}
            sx={{
              color: green[500],
            
            }}
          />
        ):<Fab
         className="m-1"
        aria-label="Save"
        color="primary"
        sx={buttonSx}
        onClick={handleButtonClick}
      >
        {success ? <CheckIcon /> : <SaveIcon />}
      </Fab>}
         
        <Fab
        className="m-1"
        aria-label="Save"
        color="error"
        sx={buttonSx}
        onClick={()=>{props.deleterule(props.index)}}
      >
        <DeleteIcon></DeleteIcon>
      </Fab>
        
      
    
         </Col>

    </Row></>
}
export default Conditions;
/** */