import { Rule } from "../models/Rule";
import axios from "axios";
 const url="https://api.coralytics.com/";
const AddRule = async (rule:Rule) => {
    const response = await axios
          .post(url+"create_rule", {
            "rule_name": rule.rule_name,
            "rule_description": rule.rule_description,
            "rule_action_type": rule.rule_action_type,
            "rule_action_reason": rule.rule_action_reason,
            "active": rule.active,
            "rule_conditions": rule.rule_conditions,
          
          },{
            headers: {
              "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
            }
          });
          console.log(response.data)
      return response.data;
  };
  const GetRules = async (id:string) => {
    const response = await axios
          .post( url+"list_rules", {
            
          },{
            headers: {
              "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
            }
          });
         
      return response.data;
  };
  const Activerule = async (user_id:string,rule_id:string,isactive:boolean) => {
    const response = await axios
          .post(url+"update_rule", {
             
              "rule_id":rule_id,
              "active":isactive
          },{
            headers: {
              "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
            }
          });
         
      return response.data;
  };
  const Getfields = async (id:string) => {
    const response = await axios
          .post(url+"get_fields_list", {
            
          },{
            headers: {
              "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
            }
          });
         
      return response.data;
  };
  const Getoperators = async (id:string,fieldname:string) => {
    const response = await axios
          .post(url+"get_operators_list", {
            
             "field_name": fieldname
          },{
            headers: {
              "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
            }
          });
         
      return response.data;
  };
  const Getvalues = async (id:string,fieldname:string,operator_name:string) => {
    const response = await axios
          .post(url+"get_values_list", {
            
             "field_name": fieldname,
             "operator_name": operator_name
            
          },{
            headers: {
              "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
            }
          });
         
      return response.data;
  };
  const GetRuledetails = async (id:string,ruleid:string) => {
    const response = await axios
          .post(url+"get_rule", {
           
            "rule_id": ruleid,
        },{
          headers: {
            "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
          }
        });
         
      return response.data;
  };
  const deleteRule = async (id:string,ruleid:string) => {
    const response = await axios
          .post(url+"remove_rule", {
           
            "rule_id": ruleid,
        },{
          headers: {
            "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
          }
        });
         
      return response.data;
  };
const Ruleservice={
    AddRule,GetRules,Getfields,Getoperators,Getvalues,Activerule,GetRuledetails,deleteRule
};

export default Ruleservice;

