import React, { useEffect, useState, } from 'react';

import { Document, Page, pdfjs, } from "react-pdf";


function Doc(props:{allpages:boolean,link:string}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  useEffect(()=>{
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  },[]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages); 
  }

  return (
    <div >
      <Document file={{ url: props.link }} onLoadSuccess={onDocumentLoadSuccess}
    onLoadError={(error) => console.log("Inside Error", error)}
   
    >
      
       {props.allpages?Array.apply(null, Array(numPages))
    .map((x, i)=>i+1)
    .map(page => <Page scale={3}
      height={300}  pageNumber={page}/>):<Page  height={200} scale={3}  pageNumber={pageNumber}/>}
      
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p>
    </div>
  );
}
export default Doc;


