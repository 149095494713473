import './App.css';
import Navbar from './components/layout/navbar';
import Addrule from './components/rules/addlistrules';
import Newrule from './components/rules/addruel';
import Listrules from './components/rules/listrules';
import { useEffect, useState } from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dashbord from './components/Dashbord/Dashbord';
import Automation from './components/Automation/automation';
import Invituser from './components/users/invituser';
import Ecommerce_product_list from './components/items/Recent_items_list';
import MyGallery from './components/Automation/automationtest';

import "react-image-gallery/styles/scss/image-gallery.scss";

import "react-image-gallery/styles/css/image-gallery.css";
import LoginPage from './components/Auth/Login';
import Doc from './components/items/document';
import  ProtectedRoute  from './components/Auth/GuardRoutes';
import SignUp from './components/Auth/SignUp';
import authService from './services/Auth_service';
import ManualItem from './components/Manual/ItemManual';
import ClippedDrawer from './components/layout/Sidebar';


import CollapsibleTable from './components/items/backuptable';
import AgentItems from './components/agentItems/AgentItems';
import AppContext from './AppContext/Appcontext';
import Profilelayout from './components/users/Profilelayout';
import UserSettings from './components/layout/usersetting';
import Duplicates from './components/Duplicates/Duplicate';
import Ruledetails from './components/rules/Ruledetails';
import Userprofile from './components/users/profile';
import  Statistics from './components/Statistics/Statistics'
import Createsubaccount from './components/sub-Account/create_sub_account';
import Listsubaccount from './components/sub-Account/list_sub_account';
import ListProject from './components/Project/List_Project';
import CreateProject from './components/Project/Create_Project';
import Search from './components/Search/search';


function App() {
 
  const [display,setDisplay]=useState(true);
  return (
    
      <Routes>
     
    
    
   
       <Route path="/accept_invite/:id" element={<><SignUp /></>} />
       <Route  path='/' element={<ProtectedRoute/>}>
       <Route path="/automation/:itemid" element={<><Profilelayout content={MyGallery} /></>} />
       <Route path="/sidebar" element={<Profilelayout content={Newrule}/>}/>
       <Route path="/duplicate" element={<Profilelayout content={Duplicates}/>}/>
       <Route path="/items" element={<><Profilelayout content={Ecommerce_product_list} /></>} />
       <Route path="/newrule" element={<><Profilelayout content={Newrule} /></>} />
     
       <Route path="/Manualitem/:itemid/:next/:prev"  element={<AppContext><Profilelayout content={ManualItem}/></AppContext>} />       <Route path="/Listrules" element={<><Profilelayout content={Listrules} /></>} />
       <Route path="/users" element={<><Profilelayout content={Invituser} /></>} />
       <Route  index path="/" element={<><Profilelayout content={Dashbord} /></>} />
       <Route  index path="/backuptable" element={<><CollapsibleTable /></>} />
       <Route   index path="/itemsagent" element={<><Profilelayout  content={AgentItems} /></>} />
       <Route  index path="/ruledetails/:ruleid" element={<><Profilelayout content={Ruledetails} /></>} />
       <Route  index path="/Profile" element={<><Profilelayout content={Userprofile} /></>} />
  
       <Route  index path="/statistics" element={<><Profilelayout content={Statistics} /></>} />
       <Route  index path="/sub_accounts" element={<><Profilelayout content={Createsubaccount} /></>} />
       <Route  index path="/listaccounts" element={<><Profilelayout content={Listsubaccount} /></>} />
       <Route  index path="/listproject" element={<><Profilelayout content={ListProject} /></>} />
       <Route  index path="/createproject" element={<><Profilelayout content={CreateProject} /></>} />
       </Route>
    
       <Route index path="/login"  element={ <><LoginPage/></>} />
     
      
      </Routes>
  
  );
}


export default App;
