import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect } from "react";
import { Row,Col,Card} from "react-bootstrap";
import { Button, Container, FormControl, FormControlLabel, FormLabel, listClasses, Radio, RadioGroup } from '@mui/material';
import { useParams } from "react-router-dom";
import authService from "../../services/Auth_service";
import Ruleservice from "../../services/RuleGestion";
import { data } from "../Charts/barcharts";


export default function Ruledetails(){
    let {ruleid}=useParams();
    const [ruledetails,setRuledetails]= React.useState<any>();
    const [loading,setLoading]=React.useState(true);
    useEffect(()=>{
        Ruleservice.GetRuledetails(authService.getCurrentUser(),ruleid as string).then((data)=>{
            setRuledetails(data);
            console.log(ruledetails);
        }).then(()=>{
          setLoading(false);
        });
    },[])
    return loading?<div>
        loading ...
    </div>: <Container  style={{marginTop:30}}>
    <Row>
        <Col >
        <Card body style={{alignSelf:'center'}}> 
        <div style={{'marginTop':20}}>
        <Row className="center m-5">
           <h2>  Rule Details </h2>
        </Row>
        <Row>
        <Col>  <label htmlFor="formGroupExampleInput" className="form-label">Rule Name</label>
  <input type="text" disabled={true} className="form-control" value={ruledetails.data.rule_name}  id="formGroupExampleInput" placeholder="Rule Name"/></Col>
        <Col>  <label htmlFor="formGroupExampleInput"  className="form-label">Rule Description (Optional)</label>
  <input type="text" disabled={true} className="form-control"   value={ruledetails.data.rule_description}  id="formGroupExampleInput" placeholder="Rule Description"/></Col>
      </Row>
        </div>

       <div style={{'marginTop':40}}>
        <Row>
            <Col className="mb-3"> If an item matches all of the following conditions:  </Col>    
        </Row>
        {ruledetails.data.rule_conditions.map((r)=>{
            return <Row className="mt-3">
              <Col>
              <label htmlFor="formGroupExampleInput" className="form-label">Type</label>
              <input disabled={true} type="text" value={r.type} className="form-control"   id="formGroupExampleInput" placeholder="Rule Name"/>
              </Col>   
              <Col>
              <label htmlFor="formGroupExampleInput" className="form-label">Condition</label>
              <input disabled={true} type="text" value={r.condition} className="form-control"   id="formGroupExampleInput" placeholder="Rule Name"/>
              </Col>   
              <Col>
              <label htmlFor="formGroupExampleInput" className="form-label">Value</label>
              <input disabled={true} type="text" value={r.value} className="form-control"   id="formGroupExampleInput" placeholder="Rule Name"/>
              </Col>   
            </Row>
        })
        
        }
      
       
        <div style={{'marginTop':20}}>
        </div>
        <Row>   <Col> <hr style={{ 'color': 'black' }} />  </Col>  </Row>        
        <Row>
            <Col>
            <Card body>
             <Row>
                <Col style={{justifyContent:"center",display:"flex", marginTop:30}}>

    <FormControl>
     
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      
      >

        <FormControlLabel value="No action" control={<Radio />} label="No action" checked={ruledetails.data.rule_action_type=="No action"} />
        <FormControlLabel value="Manual" control={<Radio />} label="Manual" checked={ruledetails.data.rule_action_type=="Manual"} />
        <FormControlLabel value="Refuse" control={<Radio />} label="Refuse" checked={ruledetails.data.rule_action_type==="Refuse"}  />
      </RadioGroup>
    </FormControl>


                </Col>
             
             </Row>
           
             <div className='row mt-10' style={{marginTop:20}}>
               
                 <div className='col' style={{justifyContent:"center",display:"flex"}}>
                 
                 
                 </div>
                 
             </div>
            </Card>
            </Col>
        </Row>
       
        </div> 

      
     
    </Card></Col> 
    </Row>
   
    
</Container>

}