import Filter from "./filter";
import Rowchart from "./rowchart";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from "@mui/material";
import Chartrow from "./rowchart";
import { useEffect, useState } from "react";
import Setaction from "../../services/user_usage/usageservice";
import { Usage } from "../../models/Usage";
import { Col, ProgressBar, Row } from "react-bootstrap";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 5,
  width:600,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));
const getMonthName=(monthNumber) =>{
    const date = new Date();
    date.setMonth(monthNumber);
    return date.toLocaleString('en-US', { month: 'long' });
  }
function Statistics(){
  const[usage,setUsage]=useState<Usage>();
  useEffect(()=>{
 
    Setaction().then((data)=>{
      setUsage(data);
    });
  }, [])
  return  <div className='page_project'>
        <div className="row">
            <div className="card-table">
                <div className="card-table-body">
                    <h1 className="mt-0 header-title d-flex justify-content-start">Statistics</h1>
                  
                 
                    
                      
                    
                   
                     <div className="row mt-6" >
                      <div className="col">
                      <div className=' d-flex justify-content-start' style={{ fontSize: "24px", marginTop: "30px" }}>
                      {getMonthName(new Date().getMonth())} {1}, 2023 -
                      {getMonthName(new Date().getMonth())} {new Date().getDate()}, 2023
                    </div> 
                   
                      </div>
                      <div className='col-4' style={{ marginTop: "-20px",marginRight:"10px" }}>
                               <Row>
                                <Col>
                                <p className=' d-flex justify-content-start' style={{ fontSize: "18px", marginTop: "40px", fontWeight: "700" }} >
                                        Total : {usage?.user_object.total_credits}
                                    </p>
                                </Col>
                                <Col>
                                <p className=' d-flex justify-content-end' style={{ fontSize: "18px", marginTop: "40px", fontWeight: "700" }} >
                                        Total Used : {usage?.user_object.total_credits_used}
                                    </p>
                                </Col>
                               </Row>
                                   
                                    

                                    <div className="progressBar" style={{ marginTop: "10px" }}>
                                        <ProgressBar now={((usage?.user_object.total_credits_used as number/Number.parseInt(usage?.user_object.total_credits.toString()|| "1") )*100)as number} variant="primary" />
                                    </div>

                                </div>
                     </div>
                  
                  
                    <div className='row' style={{ marginTop: "30px", marginRight: "20px" }}>

                        <div className="col-4  mt-4" style={{ alignItems: "center" }}>
                            <h3 style={{ marginTop: "20px", fontSize: "20px", color: "#535dcd" }}>Listing Moderation </h3>
                            <h2 style={{ marginTop: "10px", fontSize: "20px" }}>{usage?.user_object.listing_moderation}</h2>
                           
                        </div>
                        <div className='col-4  mt-4'>
                           <h3 style={{ marginTop: "20px", fontSize: "20px", color: "#535dcd" }}>Duplicate Identification API</h3>
                            <h2 style={{ marginTop: "10px", fontSize: "20px" }}>{usage?.user_object.duplicate_identification}</h2>
                        </div>
                        <div className="col-4  mt-4" style={{ alignItems: "center" }}>
                            <h3 style={{ marginTop: "20px", fontSize: "20px", color: "#535dcd" }}>Amenities Identification API </h3>
                            <h2 style={{ marginTop: "10px", fontSize: "20px" }}>{usage?.user_object.amenities_identification}</h2>
                            
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "60px", marginRight: "20px" }}>
                    <div className="col-2  mt-4" style={{ alignItems: "center" }}>  </div>
                      <div className="col-4  mt-4" style={{ alignItems: "center" }}>
                            <h3 style={{ marginTop: "20px", fontSize: "20px", color: "#535dcd" }}>Image Improvement API </h3>
                            <h2 style={{ marginTop: "10px", fontSize: "20px" }}>{usage?.user_object.image_improvement}</h2>
                            
                        </div>
                        <div className="col-4  mt-4" style={{ alignItems: "center" }}>
                            <h3 style={{ marginTop: "20px", fontSize: "20px", color: "#535dcd" }}>Room Type Detection API </h3>
                            <h2 style={{ marginTop: "10px", fontSize: "20px" }}>{usage?.user_object.room_type_detection}</h2>
                            
                        </div>
                        <div className="col-2  mt-4" style={{ alignItems: "center" }}>  </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

}
export default Statistics;