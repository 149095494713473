import { Card, Col, Form, Row } from "react-bootstrap";
import { Input, Label } from "reactstrap";

function Createsubaccount(){
    return <div>
           <Card className="mt-5">
           <Card.Body>
        <Card.Title>Create Sub-Account</Card.Title>
              <Row className="mt-5">
                <Col>
                <Label> Email  :</Label>
                 <Input type="email"  placeholder="Email"></Input>
                </Col>
                
              </Row>
              <Row className="mt-5">
                <Col>
                <Label> Password  :</Label>
                 <Input type="password"  placeholder="Enter Password"></Input>
                </Col>
                
              </Row>
            
          <Row className="mt-5">
          <Label> Role Permission Level :</Label>
          <div key={`inline-radio`} >
     
     <Form.Check
       inline
       label="Spectateur"
       name="group1"
       type={"radio"}
      
     />
     <Form.Check
       inline
       label="Automation Specialist"
       name="group1"
       type="radio"
     
     /> 
   </div>
 
          </Row>
       
 
              </Card.Body>
           </Card>
    </div>
}
export default Createsubaccount;