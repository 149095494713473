import axios from "axios";
import { isConditionalExpression } from "typescript";
import { Profile } from "../models/Profile";

const API_URL = "/auth";
const url ="https://api.coralytics.com"
const signup = async (invit_id:any, password:any,last_name:any,first_name:any) => {
  const response = await axios
        .post(url+"/accept_invite", {
          "invite_id": invit_id,
          "first_name": first_name,
          "last_name": last_name ,
          "password": password
        });
    if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
    }
    console.log(response.data);
    return response.data;
};

const login = async (email:string, password:string) => {
  const response = await axios
        .post(url+"/login", {
            email,
            password,
        });
    if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem('role',JSON.stringify("spectateur"));
       
    }
    return response.data;
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  if(localStorage.getItem("user")!=null){
    const user= JSON.parse(localStorage.getItem("user")|| "");
  return user.id;
  }
 
};
const getCurrentuserRole = () => {
  if(localStorage.getItem("role")!=null){
    const role= JSON.parse(localStorage.getItem("user")|| "");
  return role.role
  }
 
};
const Isconnected =  () => {
;
 if(localStorage.getItem("user")==null||undefined){
  return false;
 }else{
  return true
 }
};
const GetProfile  = async () =>{
    let user :Profile;
    const response = await axios
    .post(url+"/get_user_details_mod", {
        user_id:authService.getCurrentUser()
    },{
      headers: {
        "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
      }
    });
     return response.data as Profile;
}
const update_profile  = async (last_name:string,first_name:string) =>{
 
  const response = await axios
  .post(url+"/update_user_details_mod", {
      user_id:authService.getCurrentUser(),
      lastname:last_name,
      firstname:first_name,
    
  },{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
    }
  });
   return response.data;
}
const update_profile_password  = async (newpassword:string,confirmpassword:string,oldpassword:string) =>{
 
  const response = await axios
  .post(url+"/update_user_password_mod", {
      user_id:authService.getCurrentUser(),
      password:newpassword,
      repassword:confirmpassword,
      old_password:oldpassword
  },{
    headers: {
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem("user")|| "").token
    }
  });
   return response.data;
}

const authService = {
  login,
  logout,
  getCurrentUser,
  signup,
  Isconnected,
  update_profile,
  getCurrentuserRole,
  GetProfile,
  update_profile_password
};

export default authService;